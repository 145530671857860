import { type AccountCodeTypeEnum } from '../enums/AccountCodeTypeEnum'

export interface AccountCodeConstructor {
  id: number
  code: string
  description: string
  type: AccountCodeTypeEnum
}

export class AccountCode {
  id: number
  code: string
  description: string
  type: AccountCodeTypeEnum

  constructor(data: AccountCodeConstructor) {
    this.id = data.id
    this.code = data.code
    this.description = data.description
    this.type = data.type
  }
}
