import { useSelector } from 'react-redux'

import { makeSelectBaseCurrency } from '@webapp/core/stores/global'
import { DEFAULT_FORMAT_OPTIONS } from './constants'
import { useFormatPercent, useFormatPrice } from '../'

interface FormatBudgetValuesReturns {
  formatBudgetPrice: (value: string | null, defaultValue?: string) => string
  formatBudgetPercent: (value: string | null, defaultValue?: string) => string
}

export const useFormatBudgetValues = (
  options = DEFAULT_FORMAT_OPTIONS
): FormatBudgetValuesReturns => {
  const mergeOptions = { ...DEFAULT_FORMAT_OPTIONS, ...options }
  const {
    currency,
    defaultValue: defaultValueOption,
    maximumFractionDigits,
    minimumFractionDigits,
    useSystemLocale,
  } = mergeOptions

  const formatPrice = useFormatPrice({ useSystemLocale })
  const formatPercent = useFormatPercent({ useSystemLocale })
  // Currency is either from props or from the global store
  const baseCurrency = useSelector(makeSelectBaseCurrency())

  const formatBudgetPrice = (
    value: string | null,
    defaultValue = defaultValueOption
  ): string => {
    if (value == null) {
      return defaultValue
    }

    const values: string[] = [formatPrice(value, defaultValue)]

    if (currency) {
      const currencyValue = currency === true ? baseCurrency.name : currency
      values.push(currencyValue)
    }

    return values.join(' ')
  }

  const formatBudgetPercent = (
    value: string | null,
    defaultValue = defaultValueOption
  ): string => {
    if (value == null) {
      return defaultValue
    }

    return formatPercent(
      value,
      minimumFractionDigits,
      maximumFractionDigits,
      defaultValue
    )
  }

  return {
    formatBudgetPrice,
    formatBudgetPercent,
  }
}
