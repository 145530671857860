import * as Factory from 'factory.ts'
import faker from 'faker'

import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import { type UserRoleConstructor, UserRole } from './'
import { RoleType } from '../enums/RoleTypeEnum'

export const UserRoleConstructorFactory =
  Factory.Sync.makeFactory<UserRoleConstructor>({
    id: Factory.each(() => faker.random.number()),
    name: Factory.each(() => faker.name.jobTitle()),
    type: Factory.each(() =>
      faker.random.arrayElement(Object.values(RoleType))
    ),
  })

export const UserRoleFactory = EntityFactory(
  UserRole,
  UserRoleConstructorFactory
)
