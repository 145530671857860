import { defineMessages } from 'react-intl'

const scope = `procurify.components.Navigation`

export default defineMessages({
  betaTag: {
    id: `${scope}.betaTag`,
    defaultMessage: 'Beta',
  },
  newTag: {
    id: `${scope}.newTag`,
    defaultMessage: 'New',
  },
  homeLink: {
    id: `${scope}.homeLink`,
    defaultMessage: 'Home',
  },
  orderRequestsLink: {
    id: `${scope}.orderRequestsLink`,
    defaultMessage: 'Order requests',
  },
  travelRequestsLink: {
    id: `${scope}.travelRequestsLink`,
    defaultMessage: 'Travel requests',
  },
  expenseRequestsLink: {
    id: `${scope}.expenseRequestsLink`,
    defaultMessage: 'Expense requests',
  },
  purchaseAgreementRequestsLink: {
    id: `${scope}.purchaseAgreementRequestsLink`,
    defaultMessage: 'PA requests',
  },
  requestPurchaseAgreementLink: {
    id: `${scope}.requestPurchaseAgreementLink`,
    defaultMessage: 'Purchase Agreement',
  },
  approvalLink: {
    id: `${scope}.approvalLink`,
    defaultMessage: 'Approve',
  },
  approveOrdersLink: {
    id: `${scope}.approveOrdersLink`,
    defaultMessage: 'Orders',
  },
  approveTravelLink: {
    id: `${scope}.approveTravelLink`,
    defaultMessage: 'Travel',
  },
  approveExpensesLink: {
    id: `${scope}.approveExpensesLink`,
    defaultMessage: 'Expenses',
  },
  approveBillsLink: {
    id: `${scope}.approveBillsLink`,
    defaultMessage: 'Bills',
  },
  approvePaymentsLink: {
    id: `${scope}.approvePaymentsLink`,
    defaultMessage: 'Payments',
  },
  approvePurchaseAgreementsLink: {
    id: `${scope}.approvePurchaseAgreementsLink`,
    defaultMessage: 'Purchase agreements',
  },
  approveSpendingCardsLink: {
    id: `${scope}.approveSpendingCardsLink`,
    defaultMessage: 'Spending cards',
  },
  approveVendorsLink: {
    id: `${scope}.approveVendorsLink`,
    defaultMessage: 'Vendors',
  },
  procureLink: {
    id: `${scope}.procureLink`,
    defaultMessage: 'Procure',
  },
  procureProcurementLink: {
    id: `${scope}.procureProcurementLink`,
    defaultMessage: 'Procurement',
  },
  procurePurchaseOrdersLinkV2: {
    id: `${scope}.procurePurchaseOrdersLinkV2`,
    defaultMessage: 'Purchase orders',
  },
  procurePurchaseAgreementLinkV2: {
    id: `${scope}.procurePurchaseAgreementLinkV2`,
    defaultMessage: 'Purchase agreements',
  },
  procureVendorsLink: {
    id: `${scope}.procureVendorsLink`,
    defaultMessage: 'Vendors',
  },
  procureContractsLink: {
    id: `${scope}.procureContractsLink`,
    defaultMessage: 'Contracts',
  },
  procureCatalogLink: {
    id: `${scope}.procureCatalogLink`,
    defaultMessage: 'Product catalog',
  },
  receiveLink: {
    id: `${scope}.receiveLink`,
    defaultMessage: 'Receive',
  },
  spendingCardsHeader: {
    id: `${scope}.spendingCardsHeader`,
    defaultMessage: 'Spending cards',
  },
  spendingCardsManageCardsLink: {
    id: `${scope}.spendingCardsManageCardsLink`,
    defaultMessage: 'Manage cards',
  },
  spendingCardsReconciliationLink: {
    id: `${scope}.spendingCardsReconciliationLink`,
    defaultMessage: 'Reconciliation',
  },
  spendingCardsVirtualCardsLink: {
    id: `${scope}.spendingCardsVirtualCardsLink`,
    defaultMessage: 'Virtual cards',
  },
  spendingCardsPhysicalCardLink: {
    id: `${scope}.spendingCardsPhysicalCardLink`,
    defaultMessage: 'Physical card',
  },
  spendingCardsMyTransactionsLink: {
    id: `${scope}.spendingCardsMyTransactionsLink`,
    defaultMessage: 'My transactions',
  },
  legacyPaymentsLink: {
    id: `${scope}.legacyPaymentsLink`,
    defaultMessage: 'Payment',
  },
  legacyPaymentsPaymentRecordsLink: {
    id: `${scope}.legacyPaymentsPaymentRecordsLink`,
    defaultMessage: 'Payment records',
  },
  legacyPaymentsExpenseRecordsLink: {
    id: `${scope}.legacyPaymentsExpenseRecordsLink`,
    defaultMessage: 'Expense records',
  },
  legacyPaymentsReceivingSummaryLink: {
    id: `${scope}.legacyPaymentsReceivingSummaryLink`,
    defaultMessage: 'Receiving summary',
  },
  apHeader: {
    id: `${scope}.apHeader`,
    defaultMessage: 'Accounts payable',
  },
  financialAccountLink: {
    id: `${scope}.financialAccountLink`,
    defaultMessage: 'Financial account',
  },
  apBillsLink: {
    id: `${scope}.apBillsLink`,
    defaultMessage: 'Bills',
  },
  accountsPayableCreateBillLink: {
    id: `${scope}.accountsPayableCreateBillLink`,
    defaultMessage: 'Create bill',
  },
  accountsPayableManageBillsLink: {
    id: `${scope}.accountsPayableManageBillsLink`,
    defaultMessage: 'Manage bills',
  },
  accountsPayableUnbilledOrderItemsLink: {
    id: `${scope}.accountsPayableUnbilledOrderItemsLink`,
    defaultMessage: 'Unbilled items',
  },
  accountsPayableUnbilledExpenseItemsLink: {
    id: `${scope}.accountsPayableUnbilledExpenseItemsLink`,
    defaultMessage: 'Unbilled expenses',
  },
  accountsPayableCreditCardsLink: {
    id: `${scope}.accountsPayableCreditCardsLink`,
    defaultMessage: 'Credit cards',
  },
  accountsPayableReconciliationLink: {
    id: `${scope}.accountsPayableReconciliationLink`,
    defaultMessage: 'Reconciliation',
  },
  accountsPayablePaymentLink: {
    id: `${scope}.accountsPayablePaymentLink`,
    defaultMessage: 'Payment',
  },
  accountsPayablePaymentsLink: {
    id: `${scope}.accountsPayablePaymentsLink`,
    defaultMessage: 'Payments',
  },
  apV1PaymentTrackingLink: {
    id: `${scope}.apV1PaymentTrackingLink`,
    defaultMessage: 'Payment tracking',
  },
  apV1PayeeManagementLink: {
    id: `${scope}.apV1PayeeManagementLink`,
    defaultMessage: 'Manage payees',
  },
  apV1PaymentManagementLink: {
    id: `${scope}.apV1PaymentManagementLink`,
    defaultMessage: 'Manage payments',
  },
  apV1PaymentHistoryLink: {
    id: `${scope}.apV1PaymentHistoryLink`,
    defaultMessage: 'Payment history',
  },
  accountsPayableManagePaymentsLink: {
    id: `${scope}.accountsPayableManagePaymentsLink`,
    defaultMessage: 'Manage payments',
  },
  accountsPayablePayBillsLink: {
    id: `${scope}.accountsPayablePayBillsLink`,
    defaultMessage: 'Pay bills',
  },
  accountsPayableManagePayeesLink: {
    id: `${scope}.accountsPayableManagePayeesLink`,
    defaultMessage: 'Manage payees',
  },
  reportsLink: {
    id: `${scope}.reportsLink`,
    defaultMessage: 'Reports',
  },
  spendReportsLink: {
    id: `${scope}.spendReportsLink`,
    defaultMessage: 'Spend reports',
  },
  spendReportsAccrualsLink: {
    id: `${scope}.spendReportsAccrualsLink`,
    defaultMessage: 'Accruals',
  },
  spendReportsOverallSpendLink: {
    id: `${scope}.spendReportsOverallSpendLink`,
    defaultMessage: 'Overall spend',
  },
  purchaseReportsHeader: {
    id: `${scope}.purchaseReportsHeader`,
    defaultMessage: 'Purchase reports',
  },
  purchaseReportsOrdersByVendorLink: {
    id: `${scope}.purchaseReportsOrdersByVendorLink`,
    defaultMessage: 'Orders by vendor',
  },
  purchaseReportsOrdersByUserLink: {
    id: `${scope}.purchaseReportsOrdersByUserLink`,
    defaultMessage: 'Orders by user',
  },
  purchaseReportsOrdersBySecondaryOrgCategoryLink: {
    id: `${scope}.purchaseReportsOrdersBySecondaryOrgCategoryLink`,
    defaultMessage: 'Orders by {singularSecondaryOrgCategoryLabel}',
  },
  purchaseReportsReceivingLink: {
    id: `${scope}.purchaseReportsReceivingLink`,
    defaultMessage: 'Receiving',
  },
  expenseReportsHeader: {
    id: `${scope}.expenseReportsHeader`,
    defaultMessage: 'Expense reports',
  },
  expenseReportsExpensesLink: {
    id: `${scope}.expenseReportsExpensesLink`,
    defaultMessage: 'Expenses',
  },
  expenseReportsExpensesBySecondaryOrgCategoryLink: {
    id: `${scope}.expenseReportsExpensesBySecondaryOrgCategoryLink`,
    defaultMessage: 'Expenses by {singularSecondaryOrgCategoryLabel}',
  },
  spendDashboardLink: {
    id: `${scope}.spendDashboardLink`,
    defaultMessage: 'Spend insights',
  },
  settingsLink: {
    id: `${scope}.settingsLink`,
    defaultMessage: 'Settings',
  },

  /**
   * REMOVE WITH FT_7540_REORDER_NAVIGATION
   * START OF BLOCK
   */
  dashboardLink: {
    id: `${scope}.dashboardLink`,
    defaultMessage: 'Dashboard',
  },
  requestLink: {
    id: `${scope}.requestLink`,
    defaultMessage: 'Request',
  },
  requestOrderLink: {
    id: `${scope}.requestOrderLink`,
    defaultMessage: 'Order',
  },
  requestTravelLink: {
    id: `${scope}.requestTravelLink`,
    defaultMessage: 'Travel',
  },
  requestExpenseLink: {
    id: `${scope}.requestExpenseLink`,
    defaultMessage: 'Expense',
  },
  approvalOrderLink: {
    id: `${scope}.approvalOrderLink`,
    defaultMessage: 'Order',
  },
  approvalTravelLink: {
    id: `${scope}.approvalTravelLink`,
    defaultMessage: 'Travel',
  },
  approvalExpenseLink: {
    id: `${scope}.approvalExpenseLink`,
    defaultMessage: 'Expense',
  },
  approvalBillLink: {
    id: `${scope}.approvalBillLink`,
    defaultMessage: 'Bill',
  },
  approvalPaymentLink: {
    id: `${scope}.approvalPaymentLink`,
    defaultMessage: 'Payment',
  },
  approvalPurchaseAgreementLink: {
    id: `${scope}.approvalPurchaseAgreementLink`,
    defaultMessage: 'Purchase Agreement',
  },
  approvalSpendingCardLink: {
    id: `${scope}.approvalSpendingCardLink`,
    defaultMessage: 'Spending Card',
  },
  procurePurchaseOrdersLink: {
    id: `${scope}.procurePurchaseOrdersLink`,
    defaultMessage: 'Purchase Orders',
  },
  procurePurchaseAgreementLink: {
    id: `${scope}.procurePurchaseAgreementLink`,
    defaultMessage: 'Purchase Agreements',
  },
  procureProductCatalogLink: {
    id: `${scope}.procureProductCatalogLink`,
    defaultMessage: 'Product Catalog',
  },
  spendingCardLink: {
    id: `${scope}.payLink`,
    defaultMessage: 'Spending Card',
  },
  payCardManagementLink: {
    id: `${scope}.payCardManagementLink`,
    defaultMessage: 'Manage Cards',
  },
  payReconcileTransactionsLink: {
    id: `${scope}.payReconcileTransactionsLink`,
    defaultMessage: 'Reconciliation',
  },
  spendingCardVirtualCardsLink: {
    id: `${scope}.spendingCardVirtualCardsLink`,
    defaultMessage: 'Virtual Cards',
  },
  spendingCardPhysicalCardLink: {
    id: `${scope}.spendingCardPhysicalCardLink`,
    defaultMessage: 'Physical Card',
  },
  spendingCardTransactionsLink: {
    id: `${scope}.spendingCardTransactionsLink`,
    defaultMessage: 'My Transactions',
  },
  paymentLink: {
    id: `${scope}.paymentLink`,
    defaultMessage: 'Payment',
  },
  paymentPaymentRecordsLink: {
    id: `${scope}.paymentPaymentRecordsLink`,
    defaultMessage: 'Payment Records',
  },
  paymentExpenseRecordsLink: {
    id: `${scope}.paymentExpenseRecordsLink`,
    defaultMessage: 'Expense Records',
  },
  paymentReceivingSummaryLink: {
    id: `${scope}.paymentReceivingSummaryLink`,
    defaultMessage: 'Receiving Summary',
  },
  apLink: {
    id: `${scope}.apLink`,
    defaultMessage: 'Accounts Payable',
  },
  apCreateBillLink: {
    id: `${scope}.apCreateBillLink`,
    defaultMessage: 'Create Bill',
  },
  apManageBillsLink: {
    id: `${scope}.apManageBillsLink`,
    defaultMessage: 'Manage Bills',
  },
  apUnbilledOrderItemsLink: {
    id: `${scope}.apUnbilledOrderItemsLink`,
    defaultMessage: 'Unbilled Items',
  },
  apUnbilledExpenseItemsLink: {
    id: `${scope}.apUnbilledExpenseItemsLink`,
    defaultMessage: 'Unbilled Expenses',
  },
  apCreditCardsLink: {
    id: `${scope}.apCreditCardsLink`,
    defaultMessage: 'Credit Cards',
  },
  apReconciliationLink: {
    id: `${scope}.apReconciliationLink`,
    defaultMessage: 'Reconciliation',
  },
  apPaymentLink: {
    id: `${scope}.apPaymentLink`,
    defaultMessage: 'Payment',
  },
  apPaymentsLink: {
    id: `${scope}.apPaymentsLink`,
    defaultMessage: 'Payments',
  },
  apPaymentTrackingLink: {
    id: `${scope}.apPaymentTrackingLink`,
    defaultMessage: 'Payment Tracking',
  },
  apPayeeManagementLink: {
    id: `${scope}.apPayeeManagementLink`,
    defaultMessage: 'Manage Payees',
  },
  apPaymentManagementLink: {
    id: `${scope}.apPaymentManagementLink`,
    defaultMessage: 'Manage Payments',
  },
  apPayBillsLink: {
    id: `${scope}.apPayBillsLink`,
    defaultMessage: 'Pay Bills',
  },
  apFinancialAccountLink: {
    id: `${scope}.apFinancialAccountLink`,
    defaultMessage: 'Financial Account',
  },
  apPaymentHistoryLink: {
    id: `${scope}.apPaymentHistoryLink`,
    defaultMessage: 'Payment History',
  },
  apSpendReportsLink: {
    id: `${scope}.apSpendReportsLink`,
    defaultMessage: 'Spend Reports',
  },
  apAccrualsLink: {
    id: `${scope}.apAccrualsLink`,
    defaultMessage: 'Accruals',
  },
  apOverallSpendLink: {
    id: `${scope}.apOverallSpendLink`,
    defaultMessage: 'Overall Spend',
  },
  apExpensesLink: {
    id: `${scope}.apExpensesLink`,
    defaultMessage: 'Expenses',
  },
  apReceivingLink: {
    id: `${scope}.apReceivingLink`,
    defaultMessage: 'Receiving',
  },
  purchaseReportsLink: {
    id: `${scope}.purchaseReportsLink`,
    defaultMessage: 'Purchase Reports',
  },
  reportsOrdersByVendorLink: {
    id: `${scope}.reportsOrdersByVendorLink`,
    defaultMessage: 'Orders by Vendor',
  },
  reportsOrdersByUserLink: {
    id: `${scope}.reportsOrdersByUserLink`,
    defaultMessage: 'Orders by User',
  },
  reportsOrdersBySecondaryOrgCategoryLink: {
    id: `${scope}.reportsOrdersBySecondaryOrgCategoryLink`,
    defaultMessage: 'Orders by {singularSecondaryOrgCategoryLabel}',
  },
  expenseReportsLink: {
    id: `${scope}.expenseReportsLink`,
    defaultMessage: 'Expense Reports',
  },
  reportsExpensesBySecondaryOrgCategoryLink: {
    id: `${scope}.reportsExpensesByDepartmentLink`,
    defaultMessage: 'Expenses by {singularSecondaryOrgCategoryLabel}',
  },
  purchaseSectionHeader: {
    id: `${scope}.purchaseSectionHeader`,
    defaultMessage: 'Purchase',
  },
  expenseSectionHeader: {
    id: `${scope}.expenseSectionHeader`,
    defaultMessage: 'Expense',
  },
  spendSectionHeader: {
    id: `${scope}.spendSectionHeader`,
    defaultMessage: 'Spend',
  },
  manageSectionLabel: {
    id: `${scope}.manageSectionLabel`,
    defaultMessage: 'Manage',
  },
  analyticsSectionHeader: {
    id: `${scope}.analyticsSectionHeader`,
    defaultMessage: 'Analytics',
  },
  unreadCount: {
    id: `${scope}.unreadCount`,
    defaultMessage: '{count} new items',
  },
  dataAndInsightsLink: {
    id: `${scope}.dataAndInsightsLink`,
    defaultMessage: 'Data and Insights',
  },
  spendAnalyticsLink: {
    id: `${scope}.spendAnalyticsLink`,
    defaultMessage: 'Spend Insights',
  },
  approvalVendorLink: {
    id: `${scope}.approvalVendorLink`,
    defaultMessage: 'Vendor',
  },
  whatsNewHeader: {
    id: `${scope}.whatsNewHeader`,
    defaultMessage: "What's New",
  },
  billPaymentsLink: {
    id: `${scope}.billPaymentsLink`,
    defaultMessage: 'Bill Payments',
  },
  /**
   * REMOVE WITH FT_7540_REORDER_NAVIGATION
   * END OF BLOCK
   */
})
