import * as React from 'react'

import { IconButton, IconMoveLeft } from '@procurify/ui'

import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

export interface IOpenSpendTrackerButton {
  ariaLabel?: string
  onClick: () => void
}

export const OpenSpendTrackerSheetButton = ({
  ariaLabel,
  onClick,
}: IOpenSpendTrackerButton) => {
  const intl = useProcurifyIntl()

  return (
    <IconButton
      iconComponent={<IconMoveLeft />}
      aria-label={
        ariaLabel ?? intl.formatMessage(messages.showSpendTrackerButtonLabel)
      }
      onClick={onClick}
    />
  )
}
