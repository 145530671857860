import { type IEntitlementRepository } from '@webapp/core/business-logic/interfaces/IEntitlementRepository'
import { type Entitlement } from '@webapp/core/types/Entitlement'
import { type IHttpClient } from '@webapp/infrastructure/interfaces/services/IHttpClient'
import { type IPersistentStorage } from '@webapp/infrastructure/interfaces/services/IPersistentStorage'
import { EntitlementRepositoryValidationError } from './errors'
import {
  type ModuleApiName,
  type EntitlementApiModel,
} from '../../api-models/EntitlementApiModel'
import { mapEntitlement } from '../../mappers/mapEntitlement'
import { type GetEntitlementResponse } from '../../types/GetEntitlementResponse'

interface Dependency {
  httpClient: IHttpClient
  persistentStorage: IPersistentStorage
}

export class EntitlementRepository implements IEntitlementRepository {
  private readonly isValidEntitlementData = (
    data: unknown
  ): data is EntitlementApiModel => {
    if (!data || typeof data !== 'object' || Array.isArray(data)) return false
    const modules: ModuleApiName[] = [
      'purchasing',
      'expenses',
      'ap',
      'contracts',
      'spending_cards',
      'budgets',
    ]
    return modules.every(
      (module) => typeof (data as any)[module]?.enabled === 'boolean'
    )
  }

  constructor(private readonly deps: Dependency) {}

  public readonly entitlementStorageKey: string = 'procurify.entitlement-config'

  private invalidateCache(): void {
    this.deps.persistentStorage.remove(this.entitlementStorageKey)
  }

  async refreshEntitlement(): Promise<Entitlement> {
    this.invalidateCache()
    return this.getEntitlement()
  }

  async getEntitlement(): Promise<Entitlement> {
    try {
      const res = await this.deps.httpClient.get<GetEntitlementResponse>(
        '/api/v3/integrations/tenant-management/entitlements/'
      )

      const { data: entitlementData } = res.data

      if (!this.isValidEntitlementData(entitlementData)) {
        throw new EntitlementRepositoryValidationError(
          `Invalid entitlement data: ${JSON.stringify(entitlementData)}`,
          entitlementData
        )
      }

      this.deps.persistentStorage.set(
        this.entitlementStorageKey,
        entitlementData
      )

      return mapEntitlement(entitlementData)
    } catch (err) {
      const cachedData = this.deps.persistentStorage.get(
        this.entitlementStorageKey
      )
      if (cachedData) {
        return mapEntitlement(cachedData)
      } else {
        throw err
      }
    }
  }
}
