import { useEntitlement } from '@webapp/core/hooks/useEntitlement'

export const useModuleConfig = () => {
  const entitlement = useEntitlement()

  if (!entitlement) {
    return {
      showExpenseRoutes: false,
    }
  }

  return {
    showExpenseRoutes: entitlement.module.expense.enabled,
  }
}
