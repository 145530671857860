import {
  SecondaryCategory,
  type SecondaryCategoryConstructor,
} from '@webapp/core/entities/internal/SecondaryCategory'
import {
  AccountCode,
  type AccountCodeConstructor,
} from '@webapp/platform/finance'
import { type BudgetIntervalTypeEnum } from '../enums/BudgetIntervalTypes'

export interface BudgetCategoryConstructorData {
  id: number
  uuid: uuid
  name: string
  amount: Nullable<string>
  interval: Nullable<BudgetIntervalTypeEnum>
  secondaryCategories: SecondaryCategoryConstructor[]
  accountCodes: AccountCodeConstructor[]
  startDate: ISODateString
  endDate: ISODateString
  approvedSpend: string
  invoicedSpend: string
  receivedSpend: string
  pendingSpend: string
  purchasedSpend: string
  varianceAmount: Nullable<string>
  variancePercentage: Nullable<string>
}

export class BudgetCategory {
  id: number
  uuid: uuid
  name: string
  amount: Nullable<string>
  interval: Nullable<BudgetIntervalTypeEnum>
  secondaryCategories: SecondaryCategory[]
  accountCodes: AccountCode[]
  spend: {
    approvedSpend: string
    invoicedSpend: string
    receivedSpend: string
    pendingSpend: string
    purchasedSpend: string
  }
  startDate: Date
  endDate: Date
  varianceAmount: Nullable<string>
  variancePercentage: Nullable<string>

  constructor(data: BudgetCategoryConstructorData) {
    this.id = data.id
    this.uuid = data.uuid
    this.name = data.name
    this.amount = data.amount
    this.interval = data.interval
    this.secondaryCategories = data.secondaryCategories.map(
      (secondaryCategory) => new SecondaryCategory(secondaryCategory)
    )
    this.accountCodes = data.accountCodes.map(
      (accountCode) => new AccountCode(accountCode)
    )
    this.spend = {
      approvedSpend: data.approvedSpend,
      invoicedSpend: data.invoicedSpend,
      receivedSpend: data.receivedSpend,
      pendingSpend: data.pendingSpend,
      purchasedSpend: data.purchasedSpend,
    }
    this.startDate = new Date(data.startDate)
    this.endDate = new Date(data.endDate)
    this.varianceAmount = data.varianceAmount
    this.variancePercentage = data.variancePercentage
  }
}
