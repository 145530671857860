import { defineMessages } from 'react-intl'

const scope = `procurify.pages`

export default defineMessages({
  payCardManagementTitle: {
    id: `${scope}.payCardManagement.title`,
    defaultMessage: 'Spending Card Management',
  },
  paySetupTitle: {
    id: `${scope}.paySetupTitle.title`,
    defaultMessage: 'Spending Card Setup',
  },
  apiApplicationTitle: {
    id: `${scope}.apiApplicationTitle.title`,
    defaultMessage: 'API Application',
  },
  ssoConfigurationTitle: {
    id: `${scope}.ssoConfigurationTitle.title`,
    defaultMessage: 'SSO configuration',
  },
  ssoConfigurationAccessDeniedTitle: {
    id: `${scope}.ssoConfiguration.ssoConfigurationAccessDeniedTitle`,
    defaultMessage: 'Access denied',
  },
  ssoConfigurationAccessDeniedMessage: {
    id: `${scope}.ssoConfiguration.ssoConfigurationAccessDeniedMessage`,
    defaultMessage: 'Access to this page is restricted.',
  },
  spendingCardSetupTitle: {
    id: `${scope}.spendingCardSetupTitle.title`,
    defaultMessage: 'Spending Card Setup',
  },
  manageBudgetsTitle: {
    id: `${scope}.manageBudgetsTitle.title`,
    defaultMessage: 'Manage Budgets',
  },
  createBudgetTitle: {
    id: `${scope}.createBudgetTitle.title`,
    defaultMessage: 'Create Budget',
  },
  editBudgetTitle: {
    id: `${scope}.editBudgetTitle.title`,
    defaultMessage: 'Edit Budget',
  },
  manageBudgetCategoryTitle: {
    id: `${scope}.manageBudgetCategoryTitle.title`,
    defaultMessage: 'Manage Budget Category',
  },
  createBudgetCategoryTitle: {
    id: `${scope}.createBudgetCategoryTitle.title`,
    defaultMessage: 'Create Budget Category',
  },
  editBudgetCategoryTitle: {
    id: `${scope}.editBudgetCategoryTitle.title`,
    defaultMessage: 'Edit Budget Category',
  },
  financialAccountListTitle: {
    id: `${scope}.financialAccountListTitle`,
    defaultMessage: 'Financial Accounts Management',
  },
  financialAccountSetupTitle: {
    id: `${scope}.financialAccountSetupTitle`,
    defaultMessage: 'Financial Account Setup',
  },
  financialAccountTitle: {
    id: `${scope}.financialAccountTitle`,
    defaultMessage: 'Financial Account',
  },
  integrationsListTitle: {
    id: `${scope}.integrationsListTitle.title`,
    defaultMessage: 'Integrations',
  },
  paymentManagementTitle: {
    id: `${scope}.paymentManagementTitle.title`,
    defaultMessage: 'Manage Payments',
  },
  billCreatePageTitle: {
    id: `${scope}.billCreatePageTitle`,
    defaultMessage: 'Create Bill',
  },
  billDetailsPageTitle: {
    id: `${scope}.billDetailsPageTitle`,
    defaultMessage: 'Bill Details',
  },
  manageBillsPageTitle: {
    id: `${scope}.manageBillsPageTitle`,
    defaultMessage: 'Manage Bills',
  },
  billApprovalPageTitle: {
    id: `${scope}.billApprovalPageTitle`,
    defaultMessage: 'Bill Approval',
  },
  spendingCardRequestDetailsPageTitle: {
    id: `${scope}.spendingCardRequestDetailsPageTitle`,
    defaultMessage: 'Fund Request Details',
  },
  spendingCardCustomFieldsSettingsPageTitle: {
    id: `${scope}.spendingCardCustomFieldsSettingsPageTitle`,
    defaultMessage: 'Customize Spending Cards',
  },
  spendingCardApprovalDetailsPageTitle: {
    id: `${scope}.spendingCardApprovalDetailsPageTitle`,
    defaultMessage: 'Fund Approval Details',
  },
  spendingCardApproveListPageTitle: {
    id: `${scope}.spendingCardApprovalListPageTitle`,
    defaultMessage: 'Fund Approvals',
  },
  spendingCardReconcileTransactionsPageTitle: {
    id: `${scope}.spendingCardReconcileTransactionsPageTitle`,
    defaultMessage: 'Reconcile Transactions',
  },
  settingsPageTitle: {
    id: `${scope}.settingsPageTitle`,
    defaultMessage: 'Application Settings',
  },
  settingsBillFormPageTitle: {
    id: `${scope}.settingsBillFormPageTitle`,
    defaultMessage: 'Customize AP Bill Form',
  },
  manageBudgetCategoriesPageTitle: {
    id: `${scope}.manageBudgetCategoriesPageTitle`,
    defaultMessage: 'Manage Budget Categories',
  },
  purchaseOrderListPageTitle: {
    id: `${scope}.purchaseOrderListPageTitle`,
    defaultMessage: 'Purchase Orders',
  },
  purchaseOrderDetailsPageTitle: {
    id: `${scope}.purchaseOrderDetailsPageTitle`,
    defaultMessage: 'Purchase Order Details',
  },
  purchaseOrderRevisePoPageTitle: {
    id: `${scope}.purchaseOrderRevisePoPageTitle`,
    defaultMessage: 'Revise',
  },
  purchaseOrderCreatePoPageTitle: {
    id: `${scope}.purchaseOrderCreatePoPageTitle`,
    defaultMessage: 'Create PO',
  },
  punchOutSupplierListPageTitle: {
    id: `${scope}.punchOutSupplierListPageTitle`,
    defaultMessage: 'PunchOut Suppliers',
  },
  procurementPageTitle: {
    id: `${scope}.procurementPageTitle`,
    defaultMessage: 'Create Purchase Order',
  },
  userPageTitle: {
    id: `${scope}.userPageTitle`,
    defaultMessage: 'User Profile',
  },
  netsuiteSetupPageTitle: {
    id: `${scope}.netsuiteSetupPageTitle`,
    defaultMessage: 'NetSuite Setup',
  },
  qboSetupPageTitle: {
    id: `${scope}.qboSetupPageTitle`,
    defaultMessage: 'QuickBooks Online Setup',
  },
  sageIntacctSetupPageTitle: {
    id: `${scope}.sageIntacctSetupPageTitle`,
    defaultMessage: 'Sage Intacct Setup',
  },
  dynamics365BCSetupPageTitle: {
    id: `${scope}.dynamics365BCSetupPageTitle`,
    defaultMessage: 'Microsoft Business Central Setup',
  },
  spendingCardDetailsPageTitle: {
    id: `${scope}.spendingCardDetailsPageTitle`,
    defaultMessage: 'My Card',
  },
  spendingCardRequestVirtualCardPageTitle: {
    id: `${scope}.spendingCardRequestVirtualCardPageTitle`,
    defaultMessage: 'Request Virtual Card',
  },
  spendingCardVirtualCardsPageTitle: {
    id: `${scope}.spendingCardVirtualCardsPageTitle`,
    defaultMessage: 'Virtual Cards',
  },
  spendingCardVirtualCardDetailsPageTitle: {
    id: `${scope}.spendingCardVirtualCardDetailsPageTitle`,
    defaultMessage: 'Card Details',
  },
  spendingCardVirtualCardRequestDetailsPageTitle: {
    id: `${scope}.spendingCardVirtualCardRequestDetailsPageTitle`,
    defaultMessage: 'Virtual Card Request Details',
  },
  spendingCardApprovalListPageTitle: {
    id: `${scope}.spendingCardApprovalListPageTitle`,
    defaultMessage: 'Spending Card Approvals',
  },
  spendingCardApproveFundRequestPageTitle: {
    id: `${scope}.spendingCardApproveFundRequestPageTitle`,
    defaultMessage: 'Fund Approval Details',
  },
  spendingCardApproveVirtualCardRequestPageTitle: {
    id: `${scope}.spendingCardApproveVirtualCardRequestPageTitle`,
    defaultMessage: 'Virtual Card Approval Details',
  },
  spendingCardPhysicalCardPageTitle: {
    id: `${scope}.spendingCardPhysicalCardPageTitle`,
    defaultMessage: 'Physical Card',
  },
  spendingCardCreateFundRequestPageTitle: {
    id: `${scope}.spendingCardCreateFundRequestPageTitle`,
    defaultMessage: 'Request new fund',
  },
  spendingCardFundRequestDetailsPageTitle: {
    id: `${scope}.spendingCardFundRequestDetailsPageTitle`,
    defaultMessage: 'Fund Request Details',
  },
  spendingCardFundDetailsPageTitle: {
    id: `${scope}.spendingCardFundDetailsPageTitle`,
    defaultMessage: 'Fund Details',
  },
  spendingCardTransactionsPageTitle: {
    id: `${scope}.spendingCardTransactionsPageTitle`,
    defaultMessage: 'My Transactions',
  },
  spendingCardVirtualCardTopUpDetailsPageTitle: {
    id: `${scope}.spendingCardVirtualCardTopUpDetailsPageTitle`,
    defaultMessage: 'Top-up Details',
  },
  receiveListPageTitle: {
    id: `${scope}.receiveListPageTitle`,
    defaultMessage: 'PO Receiving',
  },
  receiveListBreadcrumb: {
    id: `${scope}.receiveListBreadcrumb`,
    defaultMessage: 'PO receiving',
  },
  receiveDetailsPageTitle: {
    id: `${scope}.receiveDetailsPageTitle`,
    defaultMessage: 'PO Receive Details',
  },
  receiveDetailsBreadcrumb: {
    id: `${scope}.receiveDetailsBreadcrumb`,
    defaultMessage: 'PO receive details',
  },
  instanceManagementPageTitle: {
    id: `${scope}.instanceManagementPageTitle`,
    defaultMessage: 'Instance Management',
  },
  receiveListBreadcrumbTitle: {
    id: `${scope}.receiveListBreadcrumbTitle`,
    defaultMessage: 'Purchase order receiving',
  },
  spendAnalyticsPageTitle: {
    id: `${scope}.spendAnalyticsPageTitle`,
    defaultMessage: 'Spend insights',
  },
  loginPageTitle: {
    id: `${scope}.loginPageTitle`,
    defaultMessage: 'Login',
  },
  contractListTitle: {
    id: `${scope}.contractListTitle`,
    defaultMessage: 'Contract Repository',
  },
  contractCreateTitle: {
    id: `${scope}.contractCreateTitle`,
    defaultMessage: 'New Contract',
  },
  contractDetailsTitle: {
    id: `${scope}.contractDetailsTitle`,
    defaultMessage: 'Contract Details',
  },
  contractEditDetailsTitle: {
    id: `${scope}.contractEditDetailsTitle`,
    defaultMessage: 'Edit Contract',
  },
  vendorApprovalListPageTitle: {
    id: `${scope}.vendorApprovalListPageTitle`,
    defaultMessage: 'Vendor Approvals',
  },
  VendorApprovalDetailsPageTitle: {
    id: `${scope}.VendorApprovalDetailsPageTitle`,
    defaultMessage: 'Vendor Details',
  },
  settingsExpenseFormPageTitle: {
    id: `${scope}.settingsExpenseFormPageTitle`,
    defaultMessage: 'Customize Request For Expense Form',
  },
  paymentPayBillsPageTitle: {
    id: `${scope}.paymentPayBillsPageTitle`,
    defaultMessage: 'Pay Bills',
  },
  paymentPreparePageTitle: {
    id: `${scope}.paymentPreparePageTitle`,
    defaultMessage: 'Prepare Payment',
  },
  paymentListPageTitle: {
    id: `${scope}.paymentListPageTitle`,
    defaultMessage: 'Manage Payments',
  },
  paymentDetailsPageTitle: {
    id: `${scope}.paymentDetailsPageTitle`,
    defaultMessage: 'Payment Details',
  },
  paymentApprovalListPageTitle: {
    id: `${scope}.paymentApprovalListPageTitle`,
    defaultMessage: 'Payment Approvals',
  },
  paymentApprovalDetailsPageTitle: {
    id: `${scope}.paymentApprovalDetailsPageTitle`,
    defaultMessage: 'Payment Approval Details',
  },
  paymentManagePayeesPageTitle: {
    id: `${scope}.paymentManagePayeesPageTitle`,
    defaultMessage: 'Manage Payees',
  },
  paymentFinancialAccountPageTitle: {
    id: `${scope}.paymentFinancialAccountPageTitle`,
    defaultMessage: 'Financial Account',
  },
  supportAccessPageTitle: {
    id: `${scope}.supportAccessPageTitle`,
    defaultMessage: 'Grant Procurify Account Access',
  },
  manageFinancialAccountPageTitle: {
    id: `${scope}.manageFinancialAccountPageTitle`,
    defaultMessage: 'Manage Financial Account',
  },
  contractSettingsPageTitle: {
    id: `${scope}.contractSettingsPageTitle`,
    defaultMessage: 'Customize Contracts',
  },
  procurifyPaymentsPageTitle: {
    id: `${scope}.procurifyPaymentsPageTitle`,
    defaultMessage: 'Procurify Payments',
  },
  orderStatusPageTitle: {
    id: `${scope}.orderStatusPageTitle`,
    defaultMessage: 'Order Status',
  },
  approvalRoutingPageTitle: {
    id: `${scope}.approvalRoutingPageTitle`,
    defaultMessage: 'Manage Approval Routing',
  },
  manageBankAccountsPageTitle: {
    id: `${scope}.manageBankAccountsPageTitle`,
    defaultMessage: 'Manage Bank Accounts',
  },
  unbilledItemsPageTitle: {
    id: `${scope}.unbilledItemsPageTitle`,
    defaultMessage: 'Unbilled Items',
  },
  NoPermissionsTitle: {
    id: `${scope}.NoPermissionsTitle`,
    defaultMessage: 'Account permissions pending',
  },
  IntegrationMergeQboPageTitle: {
    id: `${scope}.IntegrationMergeQboPageTitle`,
    defaultMessage: 'QuickBooks Online setup',
  },
})
