import * as React from 'react'

import { TextField } from '@material-ui/core/'
import styled, { css } from 'styled-components'

import { type ITextFieldProps } from '../TextField'

const TextFieldStyled = styled((props: ITextFieldProps) => {
  const { InputProps, inputProps, variant, ...otherProps } = props

  return (
    <TextField
      classes={{
        root: 'root',
      }}
      inputProps={{
        ...inputProps,
        className: 'nativeInput',
        'data-lpignore': 'true', // ignore lastpass
        'data-1p-ignore': 'true', // ignore 1password
      }}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        classes: {
          disabled: 'inputDisabled',
          error: 'inputError',
          focused: 'inputFocused',
          root: 'input',
          multiline: 'multiline',
          inputMultiline: 'inputMultiline',
        },
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        classes: {
          asterisk: 'formLabelAsterisk',
          disabled: 'formLabelDisabled',
          error: 'formLabelError',
          focused: 'formLabelFocused',
          root: 'inputLabel',
        },
        shrink: true,
      }}
      FormHelperTextProps={{
        ...props.FormHelperTextProps,
        classes: {
          root: 'formHelperTextRoot',
        },
      }}
      {...otherProps}
    />
  )
})`
  .root {
    margin-bottom: ${(props) => props.theme.typography.pxToRem(16)};
    margin-top: ${(props) => props.theme.typography.pxToRem(16)};
  }

  .input {
    ${(props) =>
      props?.InputProps?.startAdornment || props?.InputProps?.endAdornment
        ? css`
            overflow: hidden;
          `
        : null};
    background: ${(props) => props.theme.colors.content.contentBackground};
    font-family: inherit;
    font-size: ${(props) => props.theme.typography.pxToRem(14)};
    font-weight: ${(props) => props.theme.fontWeights.normal};
    border-radius: ${(props) => props.theme.typography.pxToRem(4)};
    border: 1px solid ${(props) => props.theme.colors.content.border};
    margin: 0;

    width: ${(props) => (props.fullWidth ? '100%;' : 'auto')};
  }

  .inputLabel {
    position: relative;
    font-family: inherit;
    color: ${(props) => props.theme.colors.text.primary};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    font-size: ${(props) => props.theme.typography.pxToRem(12)};
    transform: none;
    margin-bottom: ${(props) => props.theme.typography.pxToRem(4)};
  }

  .inputFocused {
    border-color: ${(props) => props.theme.colors.darkGreen};
  }

  .inputError {
    border-color: ${(props) => props.theme.colors.form.field.borderError};
  }

  .inputDisabled {
    border-color: ${(props) => props.theme.colors.content.border};
    background-color: ${(props) => props.theme.colors.form.field.disabled};
    color: ${(props) => props.theme.colors.text.disabled};
  }

  .formLabelAsterisk {
    color: ${(props) => props.theme.colors.red};
  }

  .formHelperTextRoot {
    font-family: inherit;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    color: ${(props) => props.theme.colors.text.primary};
    font-size: ${(props) => props.theme.typography.pxToRem(12)};
    margin-top: ${(props) => props.theme.typography.pxToRem(4)};
    line-height: 1.5;
    letter-spacing: 0;
  }

  .formHelperTextRoot.Mui-error {
    color: ${(props) => props.theme.colors.form.field.borderError};
  }

  .nativeInput {
    box-sizing: border-box;
    padding: 0 ${(props) => props.theme.typography.pxToRem(12)};
    height: ${(props) => props.theme.typography.pxToRem(36)};
    border: 0;

    &::-webkit-input-placeholder {
      opacity: 1;
      color: ${(props) => props.theme.colors.text.placeholder};
    }
    &::placeholder {
      opacity: 1;
      color: ${(props) => props.theme.colors.text.placeholder};
    }
  }

  .multiline {
    padding: ${(props) => props.theme.typography.pxToRem(7.5)} 0;
  }

  .inputMultiline {
    padding: 0 ${(props) => props.theme.typography.pxToRem(12)};
    line-height: 1.5;
    min-height: unset;
    height: auto;
  }

  && {
    .formLabelFocused {
      color: ${(props) => props.theme.colors.text.primary};
    }

    .formLabelError {
      color: ${(props) => props.theme.colors.text.error};
    }

    .formLabelDisabled {
      color: ${(props) => props.theme.colors.text.primary};
    }
  }
`
TextFieldStyled.displayName = 'TextFieldStyled'

export { TextFieldStyled }
