import * as React from 'react'

import { Redirect, useRouteMatch } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { isUuid } from '@webapp/core/utils/isUuid'
import { useAppRoutes, useFeatureFlag, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'
import { type OrderDetailsPageParams, OrderStatusPageStatuses } from './types'
import { useGetBreadcrumbsPresenter } from '../features/get-breadcrumbs/presenter/useGetBreadcrumbsPresenter'

const OrderDetailsViewChunk = React.lazy(() =>
  import('../feature-sets/OrderDetailsView').then(({ OrderDetailsView }) => ({
    default: OrderDetailsView,
  }))
)

export const OrderDetailsPage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()
  const match = useRouteMatch<OrderDetailsPageParams>()
  const { uuid } = match.params
  const FT_6224_MIGRATE_ORDER_DETAILS = useFeatureFlag(
    'FT_6224_MIGRATE_ORDER_DETAILS'
  )

  const breadcrumbs = useGetBreadcrumbsPresenter({ orderId: uuid })

  // If new OrderDetailsPage is not enabled, redirect to legacy version
  if (!FT_6224_MIGRATE_ORDER_DETAILS) {
    return (
      <Redirect
        to={url('LegacyOrderDetailsPage', {
          uuid,
        })}
      />
    )
  }

  // If uuid param is not a valid uuid, redirect to Order Status page
  if (!isUuid(uuid)) {
    return (
      <Redirect
        to={url('OrderStatusPage', {
          status: OrderStatusPageStatuses.PENDING,
        })}
      />
    )
  }

  return (
    <Page
      pageTitle={intl.formatMessage(messages.orderDetailsPageTitle)}
      breadcrumbs={breadcrumbs}
    >
      <OrderDetailsViewChunk />
    </Page>
  )
}
