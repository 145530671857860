export const getOrderDetailsQueryKey = (orderUuid: uuid) => {
  return ['order', 'details', orderUuid]
}

export const getBudgetCategoriesForOrderQueryKey = (
  orderId: number | undefined
) => {
  return ['budgetCategoriesForOrder', orderId]
}

// There are times we want to invalidate the spendBySecondaryCategory query but don't have access
// to all the params used to build the full query key. In those cases, it is typically safe to
// invalidate all "spendBySecondaryCategory" queries. To enable this, we've separated the base
// of the query key out from the rest.
export const getSpendBySecondaryCategoryQueryKeyBase = () => {
  return ['spendBySecondaryCategory']
}

export const getSpendBySecondaryCategoryQueryKey = ({
  secondaryCategoryId,
  orderId,
  startDate,
  endDate,
}: {
  secondaryCategoryId: number | undefined
  orderId: number | undefined
  startDate: ISODateString
  endDate: ISODateString
}) => {
  const base = getSpendBySecondaryCategoryQueryKeyBase()
  return [...base, orderId, secondaryCategoryId, startDate, endDate]
}
