import { type Enum } from '@webapp/core/types/Enum'

export interface UserDetailsPageParams {
  userId: string
}

export const ManageUsersPageTabStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const

export type ManageUsersPageTabStatus = Enum<typeof ManageUsersPageTabStatuses>

export interface ManageUsersPageParams {
  status: ManageUsersPageTabStatus
}
