import * as Factory from 'factory.ts'
import faker from 'faker'

import { SecondaryCategoryFactory } from '@webapp/core/entities/internal/SecondaryCategory'
import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import { Account, type AccountConstructor } from './Account'
import { AccountCodeConstructorFactory } from '../AccountCode/AccountCode.factory'

export const AccountConstructorFactory =
  Factory.Sync.makeFactory<AccountConstructor>({
    id: Factory.each(() => faker.random.number()),
    active: true,
    accountCode: Factory.each(() => AccountCodeConstructorFactory.build()),
    secondaryCategory: Factory.each(() => SecondaryCategoryFactory.build()),
  })

export const AccountFactory = EntityFactory(Account, AccountConstructorFactory)
