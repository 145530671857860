import * as React from 'react'

import { Redirect, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/app/components'
import { useModuleConfig } from '@webapp/app/config/useModuleConfig'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { ProcurementPage } from '@webapp/pages'

export const ProcurementRouter = () => {
  const { url, brp } = useAppRoutes()

  const {
    routes: { enablePurchasingRoutes },
  } = useModuleConfig()

  if (!enablePurchasingRoutes) return <Redirect to={url('DashboardPage')} />

  return (
    <Switch>
      <ProtectedRoute
        path={brp('ProcurementPage')}
        component={ProcurementPage}
      />

      <Redirect to={url('DashboardPage')} />
    </Switch>
  )
}
