import { type GeneralPermissionCategoryEnum } from '../enums'
import {
  type PermissionConstructor,
  Permission,
} from '../Permission/Permission'

export interface PermissionCategoryConstructor<
  T = GeneralPermissionCategoryEnum,
> {
  id: T
  permissions: PermissionConstructor[]
}

export class PermissionCategory<T = GeneralPermissionCategoryEnum> {
  id: T
  permissions: Permission[]

  constructor(data: PermissionCategoryConstructor<T>) {
    this.id = data.id
    this.permissions = data.permissions.map(
      (permission) => new Permission(permission)
    )
  }
}
