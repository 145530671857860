import { type AccountConstructor } from '@webapp/platform/finance'
import { type AccountsApiModel } from '../api-models/AccountsApiModel'

export const mapAccountsApiModel = ({
  id,
  account_code,
  active,
  department,
}: AccountsApiModel): AccountConstructor => ({
  id,
  accountCode: {
    code: account_code.code,
    description: account_code.description,
    id: account_code.id,
    type: account_code.account_type,
  },
  active,
  secondaryCategory: {
    id: department.id,
    name: department.name,
    primaryCategory: department.branch,
  },
})
