import { type AttachmentConstructorData } from '../../entities/internal/Attachment'
import { type AttachmentApiModel } from '../api-models/AttachmentApiModel'

export const mapAttachmentApiModel = (
  attachment: AttachmentApiModel
): AttachmentConstructorData => ({
  dateCreated: attachment.created_at,
  file: attachment.file,
  id: attachment.id,
  length: attachment.length,
  mimetype: attachment.mimetype,
  name: attachment.name,
  uuid: attachment.uuid,
})
