import * as React from 'react'

import { useAuthentication } from '@procurify/authentication'
import * as Sentry from '@sentry/react'
import { useDispatch } from 'react-redux'

import {
  ENTITLEMENT_ACCESS_CONFIG_DEFAULTS,
  entitlementService,
  EntitlementServiceError,
  setAllBooleansToTrue,
} from '@webapp/core/services/Entitlement'
import {
  FEATURE_FLAG_DEFAULTS,
  FeatureFlagService,
} from '@webapp/core/services/FeatureFlag'
import {
  type RenderedBy,
  RENDERED_BY,
  updateEntitlementAccessConfig,
  updateFlags,
  updateGlobal,
  updateOrderCustomfield,
  updateOrderItemCustomfield,
} from '@webapp/core/stores/global'
import {
  formatDataFromGlobalResponse,
  getLocaleFromGlobalResponse,
} from './utils'
import { CustomFieldAPI } from '../../../api/CustomFieldAPI'
import { updateLocale } from '../../../stores/i18n'

export interface IApplicationContextProviderProps {
  renderedBy?: RenderedBy
}

export const ApplicationContextProvider = React.memo(
  ({
    renderedBy = RENDERED_BY.REACT,
    children,
  }: React.PropsWithChildren<IApplicationContextProviderProps>) => {
    const { isLoading, isAuthenticated, userGlobalData, logout } =
      useAuthentication()
    const dispatch = useDispatch()

    React.useEffect(() => {
      if (isLoading || !isAuthenticated) return

      dispatch(
        updateGlobal({
          ...formatDataFromGlobalResponse(userGlobalData),
          renderedBy,
        })
      )

      dispatch(updateLocale(getLocaleFromGlobalResponse(userGlobalData)))

      const initFeatureFlagServicePromise = FeatureFlagService.initialize({
        clientId: userGlobalData?.sdk_keys?.LAUNCHDARKLY_CLIENT_ID,
        user: userGlobalData?.user_profile,
        domain: userGlobalData?.domain,
      })

      // Cache Order CustomFields
      const orderCustomFieldPromise =
        CustomFieldAPI.getOrderCustomfields().then((res) => res.data)
      const orderItemCustomFieldPromise =
        CustomFieldAPI.getOrderItemCustomfields().then((res) => res.data)

      Promise.all([
        initFeatureFlagServicePromise,
        orderCustomFieldPromise,
        orderItemCustomFieldPromise,
      ])
        .then(
          ([featureFlagService, orderCustomFields, orderItemCustomFields]) => {
            dispatch(updateOrderCustomfield(orderCustomFields))
            dispatch(updateOrderItemCustomfield(orderItemCustomFields))

            if (featureFlagService.initialized) {
              const featureFlags = featureFlagService.getAllFlags()
              dispatch(updateFlags(featureFlags))

              if (featureFlags.FT_7508_IMPLEMENT_ENTITLEMENTS) {
                entitlementService
                  .getEntitlementAccessConfig()
                  .then((entitlementConfig) => {
                    dispatch(updateEntitlementAccessConfig(entitlementConfig))
                  })
                  .catch((err) => {
                    Sentry.withScope((scope) => {
                      scope.setLevel(Sentry.Severity.Critical)
                      Sentry.captureException({
                        message: `ApplicationContextProvider rendered by: ${renderedBy} threw an error`,
                        originalException: err,
                      })
                    })

                    dispatch(
                      updateEntitlementAccessConfig({
                        ...ENTITLEMENT_ACCESS_CONFIG_DEFAULTS,
                      })
                    )

                    if (err instanceof EntitlementServiceError) {
                      logout()
                    }
                  })
              } else {
                dispatch(
                  updateEntitlementAccessConfig(
                    setAllBooleansToTrue(ENTITLEMENT_ACCESS_CONFIG_DEFAULTS)
                  )
                )
              }
            }
          }
        )
        .catch((err) => {
          Sentry.withScope((scope) => {
            scope.setLevel(Sentry.Severity.Critical)
            Sentry.captureException({
              message: `ApplicationContextProvider rendered by: ${renderedBy} threw an error`,
              originalException: err,
            })
          })

          dispatch(
            updateFlags({
              ...FEATURE_FLAG_DEFAULTS,
            })
          )

          dispatch(
            updateEntitlementAccessConfig({
              ...ENTITLEMENT_ACCESS_CONFIG_DEFAULTS,
            })
          )
        })
    }, [isLoading, isAuthenticated, userGlobalData])

    return <>{children}</>
  }
)
