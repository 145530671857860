import * as Factory from 'factory.ts'
import faker from 'faker'

import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import {
  PrimaryCategory,
  type PrimaryCategoryConstructor,
} from './PrimaryCategory'

export const PrimaryCategoryConstructorFactory =
  Factory.Sync.makeFactory<PrimaryCategoryConstructor>({
    id: Factory.each(() => faker.random.number()),
    name: Factory.each(() => faker.address.city()),
  })

export const PrimaryCategoryFactory = EntityFactory(
  PrimaryCategory,
  PrimaryCategoryConstructorFactory
)
