import { type PrimaryCategory } from '../PrimaryCategory'

export interface SecondaryCategoryConstructor {
  id: number
  name: string
  primaryCategory: Nullable<PrimaryCategory>
}

export class SecondaryCategory {
  id: number
  name: string
  primaryCategory: Nullable<PrimaryCategory>

  constructor(data: SecondaryCategoryConstructor) {
    this.id = data.id
    this.name = data.name
    this.primaryCategory = data.primaryCategory
  }

  get nameWithPrimaryCategory(): string {
    return this.primaryCategory?.name
      ? `${this.primaryCategory.name} - ${this.name}`
      : this.name
  }
}
