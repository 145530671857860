import * as React from 'react'

import { useSelector } from 'react-redux'
import { Redirect, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/app/components'
import { useModuleConfig } from '@webapp/app/config/useModuleConfig'
import { uuidRegExp } from '@webapp/config/react-router'
import { makeSelectFeatureSwitches } from '@webapp/core/stores/global'
import { useBillsPermissions, useFeatureFlag } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import {
  BillCreatePage,
  BillListPage,
  BillRequestDetailsPage,
} from '@webapp/pages'
import { BillApprovalDetailsPage } from '@webapp/pages/BillApprovalDetailsPage'
import { BillListPageStatuses } from '@webapp/pages/BillListPage'

export const BillRouter = () => {
  const { url, brp } = useAppRoutes()

  const {
    routes: { enableEverythingButExpenseApRoutes },
  } = useModuleConfig()

  const CL_1058_AP_GRANULAR_PERMISSIONS = useFeatureFlag(
    'CL_1058_AP_GRANULAR_PERMISSIONS'
  )

  const FT_7515_FIX_CREATE_BILL_PERMISSION_ACCESS = useFeatureFlag(
    'FT_7515_FIX_CREATE_BILL_PERMISSION_ACCESS'
  )

  const { canViewBills } = useBillsPermissions()

  const { disable_ap_navigation: disableAPNavigation } = useSelector(
    makeSelectFeatureSwitches()
  )

  const canNavigateToCreateBillPage = FT_7515_FIX_CREATE_BILL_PERMISSION_ACCESS
    ? canViewBills && enableEverythingButExpenseApRoutes
    : enableEverythingButExpenseApRoutes

  return (
    <Switch>
      {disableAPNavigation && (
        <Redirect path='/bills' to={url('DashboardPage')} />
      )}

      <Redirect
        exact
        from={brp('BillListPage', {
          status: '',
        })}
        to={url('BillListPage', {
          status: BillListPageStatuses.DRAFT,
        })}
      />

      <ProtectedRoute
        path={brp('BillRequestDetailsPage', {
          uuid: `:uuid(${uuidRegExp.source})`,
        })}
        component={BillRequestDetailsPage}
      />

      {enableEverythingButExpenseApRoutes && (
        <ProtectedRoute
          path={brp('BillApprovalDetailsPage', {
            uuid: `:uuid(${uuidRegExp.source})`,
          })}
          component={BillApprovalDetailsPage}
        />
      )}

      <ProtectedRoute
        path={brp('BillListPage', {
          status: ':status?',
        })}
        component={BillListPage}
        navigationPermissions={
          CL_1058_AP_GRANULAR_PERMISSIONS ? ['ap__manage_bills'] : ['ap__bills']
        }
      />

      {canNavigateToCreateBillPage && (
        <ProtectedRoute
          path={brp('BillCreatePage', {
            uuid: ':uuid?',
          })}
          component={BillCreatePage}
        />
      )}

      <Redirect to={url('DashboardPage')} />
    </Switch>
  )
}
