import { type Enum } from '@webapp/utils'

export const GeneralPermissionCategories = {
  APPROVE: 'approve',
  EXPENSE_TRAVEL: 'expense_travel',
  SPENDING_CARD: 'spending_card',
  ORDER: 'order',
  PROCURE: 'procure',
  RECEIVE: 'receive',
  ACCOUNTS_PAYABLE: 'accounts_payable',
  REPORTS: 'reports',
  SPEND_DASHBOARD: 'spend_dashboard',
} as const
export type GeneralPermissionCategoryEnum = Enum<
  typeof GeneralPermissionCategories
>
