import { mapDepartment } from '@webapp/core/data-access/mappers/mapDepartment'
import { mapAccountCodeApiModel } from '@webapp/platform/finance'
import { type BudgetCategoryConstructorData } from '../../entities/BudgetCategory'
import { type BudgetCategoryApiModel } from '../api-models/BudgetCategoryApiModel'

export const mapBudgetCategoryApiModel = (
  budgetCategory: BudgetCategoryApiModel
): BudgetCategoryConstructorData => ({
  id: budgetCategory.id,
  uuid: budgetCategory.uuid,
  name: budgetCategory.name,
  amount: budgetCategory.budget,
  interval: budgetCategory.interval,
  secondaryCategories: budgetCategory.attributes.departments.map(mapDepartment),
  accountCodes: budgetCategory.attributes.account_codes.map(
    mapAccountCodeApiModel
  ),
  startDate: budgetCategory.start_datetime,
  endDate: budgetCategory.end_datetime,
  approvedSpend: budgetCategory.approved,
  invoicedSpend: budgetCategory.invoiced,
  receivedSpend: budgetCategory.received,
  pendingSpend: budgetCategory.pending,
  purchasedSpend: budgetCategory.purchased,
  varianceAmount: budgetCategory.variance,
  variancePercentage: budgetCategory.variance_percentage,
})
