import * as React from 'react'

import { Text } from '@procurify/ui'

import { useProcurifyIntl } from '@webapp/hooks'
import { type NavigationNotificationCount } from '@webapp/stores/notificationCount'
import { NavigationLink } from './components/NavigationLink'
import messages from './messages'
import { NavigationSectionStyled } from './styles/NavigationSectionStyled'
import { NavigationStyled } from './styles/NavigationStyled'
import { type INavigationItem } from './types'

export interface INavigationItems {
  /**
   *  Make these non-optional with FT_7540_REORDER_NAVIGATION removal
   */
  home?: INavigationItem
  approve?: INavigationItem
  expense?: INavigationItem[]
  purchase?: INavigationItem[]
  analytics?: INavigationItem[]

  /**
   * @deprecated - remove with FT_7540_REORDER_NAVIGATION
   */
  whats_new__procurify_payments?: INavigationItem
  dashboard?: INavigationItem
  procure?: INavigationItem[]
  manage?: INavigationItem[]

  spend: INavigationItem[]
  settings: INavigationItem
}

export interface INavigationProps {
  location?: string
  notificationCount?: NavigationNotificationCount
  navigationItems: INavigationItems
  FT_7540_REORDER_NAVIGATION?: boolean
}

export const Navigation = React.memo(
  ({
    location = '/',
    notificationCount = {},
    navigationItems,
    FT_7540_REORDER_NAVIGATION = false,
  }: INavigationProps) => {
    const intl = useProcurifyIntl()
    const [openedLink, setOpenedLink] =
      React.useState<INavigationItem['id']>(null)
    const [openedLinkCollapsed, setOpenedLinkCollapsed] =
      React.useState<INavigationItem['id']>(null)

    const wrapperRef = React.useRef(null)

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenedLinkCollapsed(null)
      }
    }

    React.useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [wrapperRef])

    const hasNavigationSubs = (navItem: INavigationItem) =>
      navItem.children &&
      Array.isArray(navItem.children) &&
      navItem.children.length > 0

    const renderNavigationLink = (navItem: INavigationItem) => {
      if (!navItem) return null

      return (
        <NavigationLink
          location={location}
          notificationKey={navItem.notificationKey}
          icon={navItem.icon}
          tag={navItem?.tag}
          title={navItem.title}
          to={navItem.url}
          legacy={navItem.legacy}
          navigationSubs={navItem.children}
          id={navItem.id}
          key={navItem.id}
          matchUrls={navItem?.matchUrls}
          exactMatch={navItem?.exactMatch}
          notificationCount={notificationCount}
          isOpen={openedLink === navItem.id}
          onOpen={(navItemId) => {
            if (!hasNavigationSubs(navItem)) return
            if (openedLink === navItemId) {
              setOpenedLink(null)
            } else {
              setOpenedLink(navItemId)
            }
          }}
          isCollapsedOpen={openedLinkCollapsed === navItem.id}
          onCollapsedOpen={(navItemId) => {
            if (!hasNavigationSubs(navItem)) return
            if (openedLink !== navItemId) {
              setOpenedLinkCollapsed(navItemId)
            }
          }}
          onLinkClick={() => setOpenedLinkCollapsed(null)}
        />
      )
    }

    const closeSublinkMenuOnEsc = (e) => {
      if (e.key === 'Escape') {
        setOpenedLink(null)
        setOpenedLinkCollapsed(null)
      }
    }

    return (
      <NavigationStyled
        className='qa-navigation'
        ref={wrapperRef}
        onKeyDown={closeSublinkMenuOnEsc}
      >
        {renderNavigationLink(navigationItems.dashboard)}

        {FT_7540_REORDER_NAVIGATION && !!navigationItems.approve && (
          <NavigationSectionStyled>
            {renderNavigationLink(navigationItems.approve)}
          </NavigationSectionStyled>
        )}
        {FT_7540_REORDER_NAVIGATION && !!navigationItems.expense?.length && (
          <NavigationSectionStyled>
            <Text bold gutter>
              {intl.formatMessage(messages.expenseSectionHeader)}
            </Text>
            {navigationItems.expense.map((nav) => renderNavigationLink(nav))}
          </NavigationSectionStyled>
        )}
        {FT_7540_REORDER_NAVIGATION && !!navigationItems.purchase?.length && (
          <NavigationSectionStyled>
            <Text bold gutter>
              {intl.formatMessage(messages.purchaseSectionHeader)}
            </Text>
            {navigationItems.purchase.map((nav) => renderNavigationLink(nav))}
          </NavigationSectionStyled>
        )}
        {FT_7540_REORDER_NAVIGATION && !!navigationItems.spend?.length && (
          <NavigationSectionStyled>
            <Text bold gutter>
              {intl.formatMessage(messages.spendSectionHeader)}
            </Text>
            {navigationItems.spend.map((nav) => renderNavigationLink(nav))}
          </NavigationSectionStyled>
        )}
        {FT_7540_REORDER_NAVIGATION && !!navigationItems.analytics?.length && (
          <NavigationSectionStyled>
            <Text bold gutter>
              {intl.formatMessage(messages.analyticsSectionHeader)}
            </Text>
            {navigationItems.analytics.map((nav) => renderNavigationLink(nav))}
          </NavigationSectionStyled>
        )}

        {!FT_7540_REORDER_NAVIGATION &&
          !!navigationItems.whats_new__procurify_payments && (
            <NavigationSectionStyled>
              <Text bold gutter>
                {intl.formatMessage(messages.whatsNewHeader)}
              </Text>
              {renderNavigationLink(
                navigationItems.whats_new__procurify_payments
              )}
            </NavigationSectionStyled>
          )}
        {!FT_7540_REORDER_NAVIGATION && !!navigationItems.procure?.length && (
          <NavigationSectionStyled>
            <Text bold gutter>
              {intl.formatMessage(messages.purchaseSectionHeader)}
            </Text>
            {navigationItems.procure.map((nav) => renderNavigationLink(nav))}
          </NavigationSectionStyled>
        )}
        {!FT_7540_REORDER_NAVIGATION && !!navigationItems.spend?.length && (
          <NavigationSectionStyled>
            <Text bold gutter>
              {intl.formatMessage(messages.spendSectionHeader)}
            </Text>
            {navigationItems.spend.map((nav) => renderNavigationLink(nav))}
          </NavigationSectionStyled>
        )}
        {!FT_7540_REORDER_NAVIGATION && !!navigationItems.manage?.length && (
          <NavigationSectionStyled>
            <Text bold gutter>
              {intl.formatMessage(messages.manageSectionLabel)}
            </Text>
            {navigationItems.manage.map((nav) => renderNavigationLink(nav))}
          </NavigationSectionStyled>
        )}

        {!!navigationItems.settings && (
          <NavigationSectionStyled>
            {renderNavigationLink(navigationItems.settings)}
          </NavigationSectionStyled>
        )}
      </NavigationStyled>
    )
  }
)

Navigation.displayName = 'Navigation'
