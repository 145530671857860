import {
  ApproverDelegatee,
  type ApproverDelegateeConstructor,
} from '@webapp/core/entities/internal/ApproverDelegatee'
import {
  PrimaryCategory,
  type PrimaryCategoryConstructor,
} from '@webapp/core/entities/internal/PrimaryCategory'
import { UserRole, type UserRoleConstructor } from '../UserRole'

export interface UserConstructor {
  id: number
  authId: number
  firstName: string
  lastName: string
  email: string
  primaryCategory: Nullable<PrimaryCategoryConstructor>
  profileImage: string
  active: boolean
  position: string
  role: Nullable<UserRoleConstructor>
  approvalDelegatee: Nullable<ApproverDelegateeConstructor>
}

export class User {
  id: number
  authId: number
  firstName: string
  lastName: string
  email: string
  primaryCategory: Nullable<PrimaryCategory>
  profileImage: string
  active: boolean
  position: string
  role: Nullable<UserRole>
  approvalDelegatee: Nullable<ApproverDelegatee>

  constructor(data: UserConstructor) {
    this.id = data.id
    this.authId = data.authId
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.email = data.email
    this.primaryCategory = data.primaryCategory
      ? new PrimaryCategory(data.primaryCategory)
      : null
    this.profileImage = data.profileImage
    this.active = data.active
    this.position = data.position
    this.role = data.role ? new UserRole(data.role) : null
    this.approvalDelegatee = data.approvalDelegatee
      ? new ApproverDelegatee(data.approvalDelegatee)
      : null
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  get hasPrimaryCategory(): boolean {
    return !!this.primaryCategory
  }

  get hasApprovalDelegatee(): boolean {
    return !!this.approvalDelegatee
  }
}
