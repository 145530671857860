import { type OrderItemVendorConstructor } from '../../entities/OrderItemVendor'
import { type PreferredVendorApiModel } from '../api-models/PreferredVendorApiModel'

export const mapPreferredVendorApiModel = ({
  id,
  name,
  is_auto_email_po_enabled,
}: PreferredVendorApiModel): OrderItemVendorConstructor => ({
  id,
  name,
  isAutoEmailPoEnabled: is_auto_email_po_enabled,
})
