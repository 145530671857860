import * as React from 'react'

import {
  Icon,
  StatusLabel,
  StatusLabelStatuses,
  Text,
  WithTooltip,
} from '@procurify/ui'

import { useProcurifyIntl } from '@webapp/hooks'
import { NavigationSublink } from './NavigationSublink'
import { type NavigationNotificationCount } from '../../../stores/notificationCount'
import messages from '../messages'
import { NavigationLinkContainerStyled } from '../styles/NavigationLinkContainerStyled'
import { NavigationLinkNotificationIndicatorStyled } from '../styles/NavigationLinkNotificationIndicatorStyled'
import { NavigationLinkStyled } from '../styles/NavigationLinkStyled'
import { NavigationSubContainerStyled } from '../styles/NavigationSubContainerStyled'
import { NavigationSubheaderStyled } from '../styles/NavigationSubheaderStyled'
import {
  type INavigationItem,
  type INavigationSub,
  type TagType,
  TagTypes,
} from '../types'

export interface INavigationLinkProps {
  location?: string
  title: string
  to: string
  icon?: React.ElementType
  tag?: TagType
  legacy?: boolean
  notificationKey: string
  notificationCount: NavigationNotificationCount
  active?: boolean
  navigationSubs: INavigationSub[]
  id: INavigationItem['id']
  matchUrls?: string[]
  exactMatch?: boolean
  isOpen?: boolean
  onOpen?: (navItemId: INavigationItem['id']) => void
  isCollapsedOpen?: boolean
  onCollapsedOpen?: (navItemId: INavigationItem['id']) => void
  onLinkClick?: () => void
}

export const matchURL = (
  url,
  location: string = '',
  matchUrls: string[] = [],
  exactMatch = false
): boolean => {
  const locationPath = location.split('?')[0]

  return [].concat(url, matchUrls).some((matchUrl) => {
    if (!matchUrl) return false

    if (exactMatch) {
      return matchUrl === locationPath
    } else {
      return matchUrl.endsWith('/')
        ? locationPath.startsWith(matchUrl)
        : matchUrl === locationPath || locationPath.startsWith(matchUrl + '/')
    }
  })
}

export const NavigationLink = ({
  location = '/',
  title,
  to,
  icon: NavigationIcon,
  tag,
  notificationKey,
  notificationCount = {},
  legacy,
  navigationSubs,
  id,
  matchUrls = [],
  exactMatch = false,
  isOpen = false,
  onOpen = () => undefined,
  isCollapsedOpen = false,
  onCollapsedOpen = () => undefined,
  onLinkClick = () => undefined,
}: INavigationLinkProps) => {
  const intl = useProcurifyIntl()

  let isSelected = false
  let isChildSelected = false

  const tagText =
    tag === TagTypes.NEW
      ? intl.formatMessage(messages.newTag)
      : intl.formatMessage(messages.betaTag)

  if (to) {
    isSelected = matchURL(to, location, matchUrls, exactMatch)
  } else if (navigationSubs) {
    isChildSelected = !!navigationSubs.find((o) =>
      matchURL(o.url, location, o.matchUrls)
    )
  }

  React.useEffect(() => {
    if (!isOpen && isChildSelected) {
      onOpen(id)
    }
  }, [isChildSelected])

  const hasNavigationSubs =
    navigationSubs && Array.isArray(navigationSubs) && navigationSubs.length > 0

  const badgeCount =
    notificationKey && notificationCount[notificationKey] > 0
      ? notificationCount[notificationKey]
      : null

  return (
    <NavigationLinkContainerStyled
      className={`${hasNavigationSubs ? 'has-navigation-subs' : ''} ${
        isCollapsedOpen ? 'is-collapsed-open' : ''
      }`}
    >
      <NavigationLinkStyled
        to={to}
        legacy={legacy}
        onClick={() => {
          onOpen(id)
          onCollapsedOpen(id)
        }}
        id={id}
        className={`${
          isSelected || (isChildSelected && !isOpen)
            ? 'active'
            : hasNavigationSubs
              ? isOpen
                ? 'is-open'
                : ''
              : ''
        } ${isCollapsedOpen ? 'is-collapsed-open' : ''}`}
        onLinkClick={onLinkClick}
      >
        {NavigationIcon && (
          <NavigationIcon size={16} className='nav-icon' aria-label={title} />
        )}

        <Text>{title}</Text>
        {badgeCount && (
          <NavigationLinkNotificationIndicatorStyled
            aria-label={intl.formatMessage(messages.unreadCount, {
              count: badgeCount,
            })}
          />
        )}
        {tag && (
          <StatusLabel
            type={StatusLabelStatuses.NEW}
            text={tagText}
            fontSize={12}
          />
        )}
        <div className='chevron-container'>
          {hasNavigationSubs && (
            <Icon
              icon={isOpen ? 'chevron-thick-up' : 'chevron-thick-down'}
              size={8}
              className='nav-chevron'
            />
          )}
        </div>
        {!hasNavigationSubs && (
          <WithTooltip title={title} placement='right'>
            <div className='navigation-tooltip-trigger' />
          </WithTooltip>
        )}
      </NavigationLinkStyled>

      <div className='NavigationExpander' />

      <NavigationSubContainerStyled isOpen={isOpen}>
        {hasNavigationSubs &&
          navigationSubs.map((navigationSub, index) => {
            if (!navigationSub) return
            if (navigationSub.isSubheader) {
              return (
                <NavigationSubheaderStyled key={navigationSub.id + '_' + index}>
                  {navigationSub.title}
                </NavigationSubheaderStyled>
              )
            } else {
              return (
                <NavigationSublink
                  id={navigationSub.id}
                  key={navigationSub.id + '_' + index}
                  to={navigationSub.url}
                  tag={navigationSub?.tag}
                  title={navigationSub.title}
                  legacy={navigationSub.legacy}
                  notificationKey={navigationSub.notificationKey}
                  active={matchURL(
                    navigationSub.url,
                    location,
                    navigationSub.matchUrls,
                    navigationSub.exactMatch
                  )}
                  notificationCount={notificationCount}
                  onClick={onLinkClick}
                />
              )
            }
          })}
      </NavigationSubContainerStyled>
    </NavigationLinkContainerStyled>
  )
}
