/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter, type RouterState } from 'connected-react-router'
import { combineReducers } from 'redux'

import {
  type IGlobalState,
  reducer as GlobalReducer,
} from '@webapp/core/stores/global'
import {
  type ILanguageProviderState,
  reducer as i18nReducer,
} from '@webapp/stores/i18n'
import {
  type NotificationCountState,
  reducer as NotificationCountReducer,
} from '@webapp/stores/notificationCount'
import {
  type IPayApprovalsStoreState,
  reducer as PayApprovalsReducer,
} from '@webapp/stores/payApprovals'
import {
  type IPayCardManagementStoreState,
  reducer as PayCardManagementReducer,
} from '@webapp/stores/payCardManagement'
import history from '@webapp/utils/history'

export interface RootState {
  i18n: ILanguageProviderState
  router: RouterState
  global: IGlobalState
  payApprovals: IPayApprovalsStoreState
  notificationCount: NotificationCountState
  payCardManagement: IPayCardManagementStoreState
}

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: GlobalReducer,
    i18n: i18nReducer,
    router: connectRouter(history),
    payApprovals: PayApprovalsReducer,
    notificationCount: NotificationCountReducer,
    payCardManagement: PayCardManagementReducer,
    ...injectedReducers,
  })

  return rootReducer
}
