import * as React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useModuleConfig } from '@webapp/app/config/useModuleConfig'
import {
  makeSelectApplicationStatus,
  makeSelectFeatureFlags,
  makeSelectFeatureSwitches,
  makeSelectIsAppReady,
  makeSelectNavigationPermissions,
  makeSelectRole,
  makeSelectSetupProgress,
} from '@webapp/core/stores/global'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { makeSelectNotificationCount } from '@webapp/stores/notificationCount/selectors'
import { useInjectSaga } from '@webapp/utils'
import { NavigationLoader } from './components/NavigationLoader'
import { Navigation } from './Navigation'
import { getBaseNavigation, getBaseNavigationLegacy } from './NavigationMapping'
import { NavigationSections, NavigationSectionsLegacy } from './types'
import {
  filterByApplicationStatus,
  filterByCondition,
  filterByPermissions,
  getAnalyticsSection,
  getExpenseSection,
  getManageSection,
  getProcureSection,
  getPurchaseSection,
  getSpendSection,
  getSpendSectionLegacy,
} from './utils'
import { fetchCounts } from '../../stores/notificationCount'
import saga from '../../stores/notificationCount/sagas'

export const API_NOTIFICATION_COUNT = '/api/v3/users/me/notification-count/'

export const ConnectedNavigation = React.memo(() => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  useInjectSaga({ key: 'ConnectedNavigation', saga })

  const dispatch = useDispatch()
  const { pathname, hash } = useLocation()

  const navigationPermissions = useSelector(makeSelectNavigationPermissions())
  const featureSwitches = useSelector(makeSelectFeatureSwitches())
  const featureFlags = useSelector(makeSelectFeatureFlags())
  const notificationCount = useSelector(makeSelectNotificationCount())
  const applicationStatus = useSelector(makeSelectApplicationStatus())
  const setupProgress = useSelector(makeSelectSetupProgress())
  const role = useSelector(makeSelectRole())
  const isAppReady = useSelector(makeSelectIsAppReady())

  React.useEffect(() => {
    dispatch(fetchCounts())
  }, [])

  const { navigation: navigationEntitlement } = useModuleConfig()

  const baseNavigation = isAppReady
    ? featureFlags.FT_7540_REORDER_NAVIGATION
      ? getBaseNavigation(intl, url)
      : getBaseNavigationLegacy(intl, url)
    : []

  const filteredNavigation = baseNavigation
    .filter(filterByApplicationStatus(applicationStatus, role))
    .filter(
      filterByCondition({
        featureFlags: { ...featureFlags, ...featureSwitches },
        setupProgress,
        entitlement: navigationEntitlement,
      })
    )
    .filter(filterByPermissions(navigationPermissions))

  const navigationItems = {
    [NavigationSections.HOME]: filteredNavigation.find(
      (navItem) => navItem.id === NavigationSections.HOME
    ),
    [NavigationSections.APPROVE]: filteredNavigation.find(
      (navItem) => navItem.id === NavigationSections.APPROVE
    ),
    [NavigationSections.EXPENSE]: getExpenseSection(filteredNavigation),
    [NavigationSections.PURCHASE]: getPurchaseSection(filteredNavigation),
    [NavigationSections.SPEND]: getSpendSection(filteredNavigation),
    [NavigationSections.ANALYTICS]: getAnalyticsSection(filteredNavigation),
    [NavigationSections.SETTINGS]: filteredNavigation.find(
      (navItem) => navItem.id === NavigationSections.SETTINGS
    ),
  }

  /**
   *
   * @deprecated - remove with FT_7540_REORDER_NAVIGATION
   */
  const navigationItemsLegacy = {
    [NavigationSectionsLegacy.DASHBOARD]: filteredNavigation.find(
      (navItem) => navItem.id === NavigationSectionsLegacy.DASHBOARD
    ),
    [NavigationSectionsLegacy.WHATS_NEW]: filteredNavigation.find(
      (navItem) => navItem.id === NavigationSectionsLegacy.WHATS_NEW
    ),
    [NavigationSectionsLegacy.PROCURE]: getProcureSection(filteredNavigation),
    [NavigationSectionsLegacy.SPEND]: getSpendSectionLegacy(filteredNavigation),
    [NavigationSectionsLegacy.MANAGE]: getManageSection(filteredNavigation),
    [NavigationSectionsLegacy.SETTINGS]: filteredNavigation.find(
      (navItem) => navItem.id === NavigationSectionsLegacy.SETTINGS
    ),
  }

  return isAppReady ? (
    <Navigation
      location={pathname + hash}
      notificationCount={notificationCount}
      navigationItems={
        featureFlags.FT_7540_REORDER_NAVIGATION
          ? navigationItems
          : navigationItemsLegacy
      }
      FT_7540_REORDER_NAVIGATION={featureFlags.FT_7540_REORDER_NAVIGATION}
    />
  ) : (
    <NavigationLoader />
  )
})
