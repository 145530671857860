import styled from 'styled-components'

import { SpaceDividerStyled } from './SpaceDividerStyled'
import { SpaceItemStyled } from './SpaceItemStyled'
import { type ISpaceProps } from '../Space'
import {
  type flexAlign,
  type flexDirection,
  type flexJustify,
  FLEX_DIRECTIONS,
} from '../types'

interface ISpaceStyledProps {
  size: ISpaceProps['size']
  direction: flexDirection
  align: flexAlign
  justify: flexJustify
  flexWrap: ISpaceProps['flexWrap']
}

export const SpaceStyled = styled.div<ISpaceStyledProps>`
  width: 100%;
  gap: ${(props) => props.theme.space[props.size]};
  display: inline-flex;
  flex-direction: ${(props) => props.direction};
  ${(props) =>
    props.align &&
    `
  align-items: ${props.align};
  `}
  justify-content: ${(props) => props.justify};
  flex-wrap: ${(props) => (props.flexWrap ? `wrap` : `nowrap`)};

  ${(props) =>
    props.direction === FLEX_DIRECTIONS.horizontal &&
    `
   > ${SpaceItemStyled} > ${SpaceDividerStyled} {
    border-top: 0;
    border-left: 1px solid ${props.theme.colors.content.border};
    height: 100%;
  }
  `}

  /* Mitigates Chromium bug for empty divs with gap property https://bugs.chromium.org/p/chromium/issues/detail?id=1161709 */
  &:empty {
    gap: unset;
  }

  /* Do not display empty SpaceItem elements that appear when components are null */
  > ${SpaceItemStyled}:empty {
    display: none;
  }
`

SpaceStyled.displayName = 'SpaceStyled'
