import { PrimaryCategory } from '@webapp/core/entities/internal/PrimaryCategory'
import { type SecondaryCategoryConstructor } from '@webapp/core/entities/internal/SecondaryCategory'
import { type DepartmentApiModel } from '../api-models/DepartmentApiModel'

export const mapDepartment = (
  dept: DepartmentApiModel
): SecondaryCategoryConstructor => ({
  id: dept.id,
  name: dept.name,
  primaryCategory: dept.branch
    ? new PrimaryCategory(dept.branch)
    : dept.location
      ? new PrimaryCategory(dept.location)
      : null,
})
