import * as React from 'react'

import { useIntersectionObserver } from 'usehooks-ts'

import {
  CardFooterStyled,
  type ICardFooterStyledProps,
} from './styles/CardFooterStyled'

interface ICardFooterProps extends ICardFooterStyledProps {}

export const CardFooter = ({
  isSticky,
  children,
}: React.PropsWithChildren<ICardFooterProps>) => {
  const { isIntersecting: isFooterAtBottom, ref } = useIntersectionObserver()

  return (
    <>
      <CardFooterStyled
        isSticky={isSticky}
        hasBorder={isSticky && !isFooterAtBottom}
      >
        {children}
      </CardFooterStyled>
      {isSticky ? <div ref={ref} /> : null}
    </>
  )
}

CardFooter.displayName = 'CardFooter'
