import * as React from 'react'

import { useSelector } from 'react-redux'
import { Redirect, Route, type RouteProps, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/app/components'
import { useModuleConfig } from '@webapp/app/config/useModuleConfig'
import { makeSelectFeatureSwitches } from '@webapp/core/stores/global'
import { useAppRoutes, useFeatureFlag } from '@webapp/hooks'
import {
  PaymentApprovalDetailsPage,
  PaymentApprovalListPage,
  PaymentDetailsPage,
  PaymentListPage,
  PaymentManagePayeesPage,
  PaymentPayBillsPage,
  PaymentPreparePage,
} from '@webapp/pages'
import { PaymentApprovalListPageStatuses } from '@webapp/pages/PaymentApprovalListPage'
import { PaymentFinancialAccountPage } from '@webapp/pages/PaymentFinancialAccountPage'
import { PaymentManagePayeesPageTabs } from '@webapp/pages/PaymentManagePayeesPage'
import { PaymentPayBillsPageStatuses } from '@webapp/pages/PaymentPayBillsPage'
import { Permissions } from '@webapp/platform/iam'

const approvalListTabFormat = Object.values(
  PaymentApprovalListPageStatuses
).join('|')

const PreparePaymentRoute = ({ path, component }: RouteProps) => {
  return (
    <ProtectedRoute
      path={path}
      component={component}
      navigationPermissions={['ap__payment__create_payment']}
      permissions={[Permissions.ACCOUNTS_PAYABLE_CREATE_PAYMENTS]}
    />
  )
}

const ManagePaymentRoute = ({ path, component }: RouteProps) => {
  return (
    <ProtectedRoute
      path={path}
      component={component}
      navigationPermissions={['ap__payment__payment_processing']}
      permissions={[Permissions.ACCOUNTS_PAYABLE_CREATE_PAYMENTS]}
    />
  )
}

const ApprovePaymentRoute = ({ path, component }: RouteProps) => {
  return (
    <ProtectedRoute
      path={path}
      component={component}
      navigationPermissions={['approval__payment']}
      permissions={[Permissions.APPROVE_PAYMENT]}
    />
  )
}

export const PaymentRouter = () => {
  const { url, brp } = useAppRoutes()

  const FT_7540_REORDER_NAVIGATION = useFeatureFlag(
    'FT_7540_REORDER_NAVIGATION'
  )

  const { disable_ap_navigation: disableAPNavigation } = useSelector(
    makeSelectFeatureSwitches()
  )

  const {
    routes: { enableEverythingButExpenseApRoutes },
  } = useModuleConfig()

  if (!enableEverythingButExpenseApRoutes)
    return <Redirect path='/payments' to={url('DashboardPage')} />

  return (
    <Switch>
      {disableAPNavigation && (
        <Redirect path='/payments' to={url('DashboardPage')} />
      )}
      {/* Prepare Payment Routes */}
      <Redirect
        exact
        from={brp('PaymentPayBillsPage', {
          status: '',
        })}
        to={url('PaymentPayBillsPage', {
          status: PaymentPayBillsPageStatuses.TO_BE_PAID,
        })}
      />

      <PreparePaymentRoute
        path={brp('PaymentPayBillsPage', { status: `:status?` })}
        component={PaymentPayBillsPage}
      />

      <PreparePaymentRoute
        path={brp('PaymentPreparePage', { uuid: `:uuid` })}
        component={PaymentPreparePage}
      />

      <Redirect
        from='/payments/create/:uuid'
        to={brp('PaymentPayBillsPage', {
          status: PaymentPayBillsPageStatuses.TO_BE_PAID,
        })}
      />

      {/* Approve Payments Routes */}
      <Redirect
        exact
        from={brp('PaymentApprovalListPage', {
          status: '',
        })}
        to={url('PaymentApprovalListPage', {
          status: PaymentApprovalListPageStatuses.PENDING,
        })}
      />

      <ApprovePaymentRoute
        path={brp('PaymentApprovalListPage', {
          status: `:status(${approvalListTabFormat})`,
        })}
        component={PaymentApprovalListPage}
      />

      <ApprovePaymentRoute
        path={brp('PaymentApprovalDetailsPage', { uuid: `:uuid` })}
        component={PaymentApprovalDetailsPage}
      />

      {/* Financial Account Route */}
      {FT_7540_REORDER_NAVIGATION ? (
        <Redirect
          path='/payments/financial-account'
          to={url('FinancialAccountPage')}
        />
      ) : (
        <ProtectedRoute
          path={brp('PaymentFinancialAccountPage')}
          component={PaymentFinancialAccountPage}
          permissions={[Permissions.SETTINGS_MANAGE_FINANCIAL_ACCOUNT]}
          featureSwitches={['bill_pay']}
        />
      )}

      {/* Manage Payments Routes */}
      <Redirect
        exact
        from='/payments/manage-payees'
        to={url('PaymentManagePayeesPage', {
          type: PaymentManagePayeesPageTabs.VENDORS,
          id: '',
          tab: '',
        })}
      />
      <ManagePaymentRoute
        path={brp('PaymentManagePayeesPage', {
          type: `:type`,
          id: `:id?`,
          tab: `:tab?`,
        })}
        component={PaymentManagePayeesPage}
      />

      <ManagePaymentRoute
        path={brp('PaymentDetailsPage', { uuid: `:uuid` })}
        component={PaymentDetailsPage}
      />

      <ManagePaymentRoute
        path={brp('PaymentListPage')}
        component={PaymentListPage}
      />

      <Route path='*' render={() => <Redirect to='/' />} />
    </Switch>
  )
}
