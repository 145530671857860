import { type RoleTypeEnum } from '../enums/RoleTypeEnum'

export interface UserRoleConstructor {
  id: number
  name: string
  type: RoleTypeEnum
}

export class UserRole {
  id: number
  name: string
  type: RoleTypeEnum

  constructor(data: UserRoleConstructor) {
    this.id = data.id
    this.name = data.name
    this.type = data.type
  }
}
