import * as React from 'react'

import '!file-loader?name=[name].[ext]!./assets/img/favicon.ico'
import { AuthenticationProvider } from '@procurify/authentication'
import { theme } from '@procurify/ui-theme'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import * as ReactDOM from 'react-dom'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { store } from '@webapp/app/store'
import { IS_DEV, IS_PROD, SENTRY_DSN } from '@webapp/infrastructure/envs'
import { queryClient } from './api/queryClient'
import './config/yup'
import { App as app } from './app'
import { GlobalStyles } from './app/components/GlobalStyles'
import { ApplicationContextProvider } from './app/providers/ApplicationContextProvider'
import { LanguageProvider } from './app/providers/LanguageProvider'
import { ScrollToTop } from './app/providers/ScrollToTop/ScrollToTop'
import { ThemeWrapper } from './app/providers/ThemeWrapper'
import { UIProvider } from './app/providers/UIProvider'

if (IS_PROD && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    // Increase depth for redux integration compatibility
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#normalization-depth
    normalizeDepth: 10,
    tracesSampleRate: 0.0225,
    // ignoreErrors matches on the following fields within an issues JSON
    // `${exception.values[0].type}: ${exception.values[0].value}`
    ignoreErrors: [
      // Noise from cancelled API requests
      'Error: Network Error',
      'Error: Request aborted',
      // Noise from LaunchDarkly
      'NetworkError: A network error occurred.',
      'LaunchDarklyUnexpectedResponseError: network error (Error)',
      'LaunchDarklyFlagFetchError: network error (Error)',
      // Noise from unauthorized API requests
      'APISessionExpiredError: Authentication credentials were not provided',
      // Ignore error caused from Chrome extensions such as 1Password
      'ResizeObserver loop completed with undelivered notifications',
    ],
  })

  Sentry.setTag(
    'fe-version',
    document.querySelector<HTMLMetaElement>('meta[name="VERSION"]')?.content
  )
}

if (IS_DEV) {
  import('./tests/browser').then(({ worker }) => {
    worker.start({
      onUnhandledRequest: 'bypass',
    })
  })
}

const MOUNT_NODE = document.getElementById('root')

const render = (App) => {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthenticationProvider>
          <Provider store={store}>
            <ThemeWrapper theme={theme}>
              <UIProvider>
                <GlobalStyles />
                <LanguageProvider>
                  <ApplicationContextProvider>
                    <ScrollToTop>
                      <App />
                    </ScrollToTop>
                  </ApplicationContextProvider>
                </LanguageProvider>
              </UIProvider>
            </ThemeWrapper>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </AuthenticationProvider>
      </BrowserRouter>
    </QueryClientProvider>,
    MOUNT_NODE
  )
}

const initiateApp = (App) => {
  render(App)
}

declare let module: { hot: any }

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./core/i18n', './app'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)

    const { App } = require('./app')

    render(App)
  })
}

// Chunked polyfill for browsers without Intl support
if (!global.Intl) {
  new Promise((resolve) => {
    resolve(import(/* webpackChunkName: 'intl' */ 'intl'))
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => initiateApp(app))
    .catch((err) => {
      throw err
    })
} else {
  initiateApp(app)
}
