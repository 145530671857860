import { put, takeLeading } from 'redux-saga/effects'

import { updateFlags, UPDATE_FEATURE_FLAGS } from '@webapp/core/stores/global'
import { updateHasFFOverride, updateLiveFFState } from './actions'

export function* overrideFeatureFlags(action) {
  const localFF =
    JSON.parse(window.localStorage.__procurify_dev_local_ff || '{}') || {}

  if (Object.keys(localFF).length > 0) {
    yield put(updateHasFFOverride(true))
  }

  yield put(updateLiveFFState(action.flags))
  yield put(
    updateFlags({
      ...action.flags,
      ...localFF,
    })
  )
}

export default function* watchUpdateFeatureFlags() {
  yield takeLeading(UPDATE_FEATURE_FLAGS, overrideFeatureFlags)
}
