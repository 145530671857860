import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const SpendAnalyticsChunk = React.lazy(() =>
  import('../containers/SpendAnalyticsView').then(({ SpendAnalyticsView }) => ({
    default: SpendAnalyticsView,
  }))
)

export const SpendAnalyticsPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.spendAnalyticsPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendAnalyticsPageTitle),
        },
      ]}
    >
      <SpendAnalyticsChunk />
    </Page>
  )
}
