import { type Enum } from '@webapp/core/types/Enum'

export const RoleType = {
  DEFAULT: 0,
  ROLE: 1,
  FILTER: 2,
  CUSTOM: 3,
} as const

export type RoleTypeEnum = Enum<typeof RoleType>
