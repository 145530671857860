import {
  type LocalPersistentStorageConfig,
  type StorageItem,
} from './LocalPersistentStorage.service'

export class MockLocalPersistentStorage {
  /*
   * Default time-to-live (TTL) values in milliseconds
   */
  static readonly DEFAULT_TTL = {
    HOUR: 60 * 60 * 1000,
    DAY: 24 * 60 * 60 * 1000,
    WEEK: 7 * 24 * 60 * 60 * 1000,
    MONTH: 30 * 24 * 60 * 60 * 1000,
  }

  private readonly defaultTTL: number | null

  private static isStorageItem<T = any>(item: any): item is StorageItem<T> {
    return typeof item === 'object' && 'value' in item && 'expiry' in item
  }

  private data: Record<string, StorageItem<any>> = {}

  /**
   * Creates an instance of LocalPersistentStorage service.
   *
   * @param {LocalPersistentStorageConfig} [config={}] - Configuration object for the local persistent storage.
   * @param {number | null} [config.defaultTTL=null] - Default time-to-live (TTL) for stored items. If not provided, defaults to null.
   */
  constructor(config: LocalPersistentStorageConfig = {}) {
    const { defaultTTL = null } = config
    this.defaultTTL = defaultTTL
  }

  get = jest.fn().mockImplementation((key: string) => {
    const item = this.data[key]

    if (!item) {
      return null
    }

    if (item.expiry && Date.now() > item.expiry) {
      this.remove(key)
      return null
    }

    return item.value
  })

  set = jest
    .fn()
    .mockImplementation((key: string, value: any, ttl?: number | null) => {
      const item: StorageItem<any> = { value, expiry: null }

      // determine ttl to use
      const _ttl = ttl !== undefined ? ttl : this.defaultTTL

      // Set an expiration if TTL has been set
      if (_ttl !== null && _ttl > 0) {
        item.expiry = Date.now() + _ttl
      }

      this.data[key] = item
    })

  remove = jest.fn().mockImplementation((key: string) => {
    delete this.data[key]
  })

  clear = jest.fn().mockImplementation(() => {
    this.data = {}
  })
}
