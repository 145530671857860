import { mapLocation } from '@webapp/core/data-access/mappers/mapLocation'
import { type UserApiModel } from '@webapp/platform/iam/data-access/api-models/UserApiModel'
import { mapUserRoleApiModel } from '@webapp/platform/iam/data-access/mapper/mapUserRoleApiModel'
import { type UserConstructor } from '@webapp/platform/iam/entities/User'

export const mapUserApiModel = (user: UserApiModel): UserConstructor => ({
  id: user.id,
  authId: user.user,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  primaryCategory: user.location ? mapLocation(user.location) : null,
  profileImage: user.profile_image,
  active: user.is_active,
  position: user.position,
  role: user.role ? mapUserRoleApiModel(user.role) : null,
  approvalDelegatee: user.approval_delegatee
    ? {
        id: user.approval_delegatee.id,
        firstName: user.approval_delegatee.firstName,
        lastName: user.approval_delegatee.lastName,
        expectedReturnDate: user.expected_return_date || null,
      }
    : null,
})
