import * as Factory from 'factory.ts'
import faker from 'faker'

import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import { AccountCode, type AccountCodeConstructor } from './AccountCode'
import { AccountCodeTypes } from '../enums/AccountCodeTypeEnum'

export const AccountCodeConstructorFactory =
  Factory.Sync.makeFactory<AccountCodeConstructor>({
    id: Factory.each(() => faker.random.number()),
    code: Factory.each(() => faker.finance.account(4)),
    description: Factory.each(() => faker.finance.accountName()),
    type: AccountCodeTypes.ASSET,
  })

export const AccountCodeFactory = EntityFactory(
  AccountCode,
  AccountCodeConstructorFactory
)
