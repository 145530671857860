import * as Factory from 'factory.ts'
import faker from 'faker'

import { PrimaryCategoryConstructorFactory } from '@webapp/core/entities/internal/PrimaryCategory'
import { SecondaryCategoryConstructorFactory } from '@webapp/core/entities/internal/SecondaryCategory'
import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import {
  DetailedUser,
  type DetailedUserConstructor,
} from '@webapp/platform/iam/entities/DetailedUser'
import { EditVarianceType } from '@webapp/platform/iam/entities/enums/EditVarianceTypeEnum'
import { UserRoleConstructorFactory } from '@webapp/platform/iam/entities/UserRole'

export const DetailedUserConstructorFactory =
  Factory.Sync.makeFactory<DetailedUserConstructor>({
    id: Factory.each(() => faker.random.number()),
    authId: Factory.each(() => faker.random.number()),
    firstName: Factory.each(() => faker.name.firstName()),
    lastName: Factory.each(() => faker.name.lastName()),
    email: Factory.each(() => faker.internet.email()),
    phone: Factory.each(() => faker.phone.phoneNumber()),
    primaryCategory: Factory.each(() =>
      PrimaryCategoryConstructorFactory.build()
    ),
    secondaryCategory: Factory.each(() =>
      SecondaryCategoryConstructorFactory.build()
    ),
    profileImage: Factory.each(() => faker.internet.url()),
    active: Factory.each(() => faker.random.boolean()),
    position: Factory.each(() => faker.name.jobTitle()),
    role: Factory.each(() => UserRoleConstructorFactory.build()),
    permissions: [],
    approvalDelegatee: Factory.each(() => null),
  })

export const DetailedUserFactory = EntityFactory(
  DetailedUser,
  DetailedUserConstructorFactory
)
