import * as Factory from 'factory.ts'
import faker from 'faker'

import { AccountCodeTypes } from '@webapp/platform/finance'
import { type AccountCodeApiModel } from '../AccountCodeApiModel'

export const AccountCodeApiModelStub =
  Factory.Sync.makeFactory<AccountCodeApiModel>({
    id: Factory.each(() => faker.random.number()),
    code: Factory.each(() => faker.random.alphaNumeric(4)),
    code_length: Factory.each(() => 4),
    description: Factory.each(() => faker.commerce.productName()),
    account_type: AccountCodeTypes.OTHER,
    active: Factory.each(() => faker.random.boolean()),
    parent: null,
  })
