export type Method =
  | 'GET'
  | 'DELETE'
  | 'HEAD'
  | 'OPTIONS'
  | 'POST'
  | 'PUT'
  | 'PATCH'

export type ResponseHeaders = Record<string, string>

export interface RequestConfig<D = any> {
  url?: string
  method?: Method
  data?: D
  headers?: ResponseHeaders
  params?: any
}

export interface HttpResponse<T = any> {
  data: T
  status: number
  headers: ResponseHeaders
}

export class HttpError<T = unknown> extends Error {
  name = 'HttpError'

  constructor(
    public status: number,
    public response: Nullable<T>
  ) {
    super(`HTTP Error ${status}`)

    Object.setPrototypeOf(this, HttpError.prototype)
    Error.captureStackTrace(this, this.constructor)
  }
}

export interface IHttpClient {
  get<T = any, D = any>(
    url: string,
    config?: RequestConfig<D>
  ): Promise<HttpResponse<T>>

  delete<T = any, D = any>(
    url: string,
    config?: RequestConfig<D>
  ): Promise<HttpResponse<T>>

  head<T = any, D = any>(
    url: string,
    config?: RequestConfig<D>
  ): Promise<HttpResponse<T>>

  options<T = any, D = any>(
    url: string,
    config?: RequestConfig<D>
  ): Promise<HttpResponse<T>>

  post<T = any, D = any>(
    url: string,
    data?: D,
    config?: RequestConfig<D>
  ): Promise<HttpResponse<T>>

  put<T = any, D = any>(
    url: string,
    data?: D,
    config?: RequestConfig<D>
  ): Promise<HttpResponse<T>>

  patch<T = any, D = any>(
    url: string,
    data?: D,
    config?: RequestConfig<D>
  ): Promise<HttpResponse<T>>
}
