import { type Enum } from '@webapp/utils'

export const SettingsPermissionCategories = {
  SETUP: 'setup',
  ACCESS: 'access',
  PAYMENTS: 'payments',
  FINANCE: 'finance',
  CUSTOMIZATIONS: 'customizations',
  TOOLS: 'tools',
} as const
export type SettingsPermissionCategoryEnum = Enum<
  typeof SettingsPermissionCategories
>
