import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const SettingsManageRolesPermissionsViewChunk = React.lazy(() =>
  import('../feature-sets/SettingsManageRolesPermissionsView').then(
    ({ SettingsManageRolesPermissionsView }) => ({
      default: SettingsManageRolesPermissionsView,
    })
  )
)

export const SettingsManageRolesPermissionsPage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={`Procurify | ${intl.formatMessage(
        messages.settingsManageRolesPermissionsPageTitle
      )}`}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.settingsTitle),
          link: url('SettingsPage'),
        },
        {
          name: intl.formatMessage(
            messages.settingsManageRolesPermissionsPageTitle
          ),
        },
      ]}
    >
      <SettingsManageRolesPermissionsViewChunk />
    </Page>
  )
}
