import { throwRepoErrorFromApiV3Error } from '@webapp/core/utils/repositories/throwRepoErrorFromApiV3Error'
import { type RepoPaginatedResponse } from '@webapp/infrastructure/interfaces/repositories/RepoPaginatedResponse'
import { type RepoStandardResponse } from '@webapp/infrastructure/interfaces/repositories/RepoStandardResponse'
import { type IHttpClient } from '@webapp/infrastructure/interfaces/services/IHttpClient'
import {
  type BudgetCategoryConstructorData,
  mapBudgetCategoryApiModel,
} from '@webapp/platform/budgets'
import { type IOrderRepository } from '../../../business-logic/interfaces/IOrderRepository'
import { type OrderConstructorData } from '../../../entities/Order'
import { mapOrderApiModel } from '../../mappers/mapOrderApiModel'
import { type GetBudgetCategoriesForOrderResponse } from '../../types/GetBudgetCategoriesForOrderResponse'
import { type GetOrderDetailsResponse } from '../../types/GetOrderDetailsResponse'
import { type GetSpendBySecondaryCategoryResponse } from '../../types/GetSpendBySecondaryCategoryResponse'
import { type PutEditOrderDetailsPayload } from '../../types/PutEditOrderDetailsPayload'

interface IDependency {
  httpClient: IHttpClient
}

export class OrderRepository implements IOrderRepository {
  constructor(private readonly deps: IDependency) {}

  async approveOrder(
    orderId: number,
    nextApproverId: Nullable<number>
  ): Promise<void> {
    await this.deps.httpClient.post(
      '/api/order/approval/',
      new URLSearchParams({
        action: 'approve',
        approver_id_by_order_id: JSON.stringify({
          [orderId]: nextApproverId,
        }),
      }).toString()
    )
  }

  async deleteOrder(orderId: number): Promise<void> {
    await this.deps.httpClient.delete(`/api/v3/orders/${orderId}/`)
  }

  async denyOrder(orderId: number): Promise<void> {
    await this.deps.httpClient.post(
      '/api/order/approval/',
      new URLSearchParams({
        action: 'deny',
        order_ids: orderId.toString(),
      }).toString()
    )
  }

  async duplicateOrder(orderId: number): Promise<void> {
    await this.deps.httpClient.post(`/api/v3/order-cart/${orderId}/recreate/`)
  }

  async editOrderDetails(
    orderId: number,
    data: PutEditOrderDetailsPayload
  ): Promise<void> {
    await this.deps.httpClient.put<void, PutEditOrderDetailsPayload>(
      `/api/v3/orders/${orderId}/`,
      data
    )
  }

  async getBudgetCategoriesForOrder(
    orderId: number
  ): Promise<
    RepoPaginatedResponse<
      BudgetCategoryConstructorData,
      { uncategorizedSpend: string }
    >
  > {
    const res =
      await this.deps.httpClient.post<GetBudgetCategoriesForOrderResponse>(
        '/api/v3/budget-categories/search/',
        {
          order_id: orderId,
        },
        {
          params: {
            matches_current_datetime: 'True',
          },
        }
      )

    const { data, metadata } = res.data

    return {
      data: data.map((budgetCategory) =>
        mapBudgetCategoryApiModel(budgetCategory)
      ),
      metadata: {
        uncategorizedSpend: metadata?.uncategorized_amount || '0',
      },
      pagination: {
        count: metadata?.pagination?.count || 0,
        page: metadata?.pagination?.current_page || 0,
        pageSize: metadata?.pagination?.page_size || 0,
        numPages: metadata?.pagination?.num_pages || 0,
      },
    }
  }

  async getSpendBySecondaryCategory(
    secondaryCategoryId: number,
    orderId: number,
    startDate: ISODateString,
    endDate: ISODateString
  ): Promise<
    | RepoStandardResponse<{
        approved: string
        invoiced: string
        pending: string
        purchased: string
        received: string
      }>
    | undefined
  > {
    try {
      const res =
        await this.deps.httpClient.get<GetSpendBySecondaryCategoryResponse>(
          '/api/v3/allocations-aggregate/',
          {
            params: {
              order_id: orderId,
              department_ids: secondaryCategoryId,
              start_date: startDate,
              end_date: endDate,
            },
          }
        )
      const { data } = res.data
      return {
        data: {
          approved: data.approved,
          invoiced: data.invoiced,
          pending: data.pending,
          purchased: data.purchased,
          received: data.received,
        },
      }
    } catch (err) {
      throwRepoErrorFromApiV3Error(err)
    }
  }

  async getDetails(uuid: uuid): Promise<
    RepoStandardResponse<
      OrderConstructorData,
      {
        permissions: {
          canApprove: boolean
          canDelete: boolean
          canEdit: boolean
          hasPendingItems: boolean
        }
      }
    >
  > {
    const res = await this.deps.httpClient.get<GetOrderDetailsResponse>(
      `/api/v3/orders/${uuid}/`
    )

    const { data: order, metadata } = res.data

    return {
      data: mapOrderApiModel(order),
      metadata: {
        permissions: {
          canApprove: metadata?.permissions.can_approve || false,
          canDelete: metadata?.permissions.can_delete || false,
          canEdit: metadata?.permissions.can_edit || false,
          hasPendingItems: metadata?.permissions.has_pending_items || false,
        },
      },
    }
  }

  async getIsOrderWithinBudget(
    orderId: number
  ): Promise<RepoStandardResponse<boolean>> {
    const res = await this.deps.httpClient.get<RepoStandardResponse<boolean>>(
      `/api/order/?context=approval&check_within_budget=True&order_ids=${orderId}`
    )

    return {
      data: res.data.data,
    }
  }

  async sendApprovalReminder(orderId: number, message: string): Promise<void> {
    await this.deps.httpClient.post<
      RepoStandardResponse,
      {
        message: Nullable<string>
      }
    >(`/api/v3/orders/${orderId}/remind-approver/`, {
      message: message || '',
    })
  }
}
