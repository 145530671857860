import { entitlementRepository } from '@webapp/core/data-access/repositories/EntitlementRepository'
import {
  EntitlementService,
  ENTITLEMENT_ACCESS_CONFIG_DEFAULTS,
  setAllBooleansToTrue,
} from './Entitlement.service'
import { EntitlementServiceError } from './errors'

const entitlementService = new EntitlementService(entitlementRepository)

export {
  entitlementService,
  EntitlementService,
  EntitlementServiceError,
  ENTITLEMENT_ACCESS_CONFIG_DEFAULTS,
  setAllBooleansToTrue,
}
