import * as React from 'react'

import { useRouteMatch } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import { useUserDetailsPage } from '@webapp/platform/iam/pages/hooks/useUserDetailsPage'
import messages from '@webapp/platform/iam/pages/messages'
import {
  ManageUsersPageTabStatuses,
  type UserDetailsPageParams,
} from '@webapp/platform/iam/pages/types'

const UserDetailsViewChunk = React.lazy(() =>
  import('../feature-sets/UserDetailsView').then(({ UserDetailsView }) => ({
    default: UserDetailsView,
  }))
)

export const UserDetailsPage = () => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()
  const match = useRouteMatch<UserDetailsPageParams>()

  const {
    data: { user },
  } = useUserDetailsPage({ userId: Number(match.params.userId) })

  return (
    <Page
      pageTitle={`${intl.formatMessage(messages.ManageUsersTitle)}`}
      breadcrumbs={[
        {
          link: url('SettingsPage'),
          name: intl.formatMessage(messages.settingsTitle),
        },
        {
          link: url('SettingsManageUsersPage', {
            status: ManageUsersPageTabStatuses.ACTIVE,
          }),
          name: intl.formatMessage(messages.ManageUsersTitle),
        },
        {
          name: user?.fullName ? user.fullName : '',
          escapeCaseFormatting: true,
        },
      ]}
    >
      <UserDetailsViewChunk />
    </Page>
  )
}
