import * as React from 'react'

import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'

import {
  ProtectedRoute,
  type IProtectedRouteProps,
} from '@webapp/app/components'
import { ApproverOverwatchView } from '@webapp/containers/ApproverOverwatchView'
import { makeSelectFeatureSwitches } from '@webapp/core/stores/global'
import { useFeatureFlag } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import {
  PunchOutGroupPage,
  PunchOutSuppliersPage,
  SettingsBillFormPage,
  SettingsImportPage,
  SettingsManageBankAccountsPage,
  SettingsManageFinancialAccountPage,
  SettingsPage,
  SpendingCardCustomFieldsSettingsPage,
  SsoConfigurationPage,
  SupportAccessPage,
} from '@webapp/pages'
import {
  ApprovalRoutingPage,
  ApprovalRoutingPageTabTypes,
} from '@webapp/pages/ApprovalRoutingPage'
import { ContractSettingsPage } from '@webapp/pages/ContractSettingsPage'
import { SettingsExpenseFormPage } from '@webapp/pages/SettingsExpenseFormPage'
import { ProtectedSsoRoute } from '@webapp/pages/SsoConfigurationPage'
import { Permissions } from '@webapp/platform/iam'
import { ManageUsersPage, MFAPreferencesPage } from '@webapp/platform/iam'
import { SettingsManageRolesPermissionsPage } from '@webapp/platform/iam/pages/SettingsManageRolesPermissionsPage'
import { UserDetailsPage } from '@webapp/platform/iam/pages/UserDetailsPage'
import { BudgetCategoryManagementRouter } from '../BudgetCategoryManagementRouter'

const ProtectedSettingsRoute = ({
  path,
  component,
  isSuperuserRoleRequired,
  ...otherProps
}: Omit<IProtectedRouteProps, 'navigationPermissions'>) => {
  return (
    <ProtectedRoute
      path={path}
      component={component}
      navigationPermissions={['settings']}
      isSuperuserRoleRequired={isSuperuserRoleRequired}
      {...otherProps}
    />
  )
}

export const SettingsRouter = () => {
  const { url, brp } = useAppRoutes()

  const {
    disable_vendor_approvals_access: isVendorApprovalDisabled,
    disable_vendor_creation: isVendorCreationDisabled,
  } = useSelector(makeSelectFeatureSwitches())

  const shouldRedirectVendorApprovalRouting =
    isVendorApprovalDisabled || isVendorCreationDisabled

  const FT_6262_REACT_APPROVAL_ROUTING = useFeatureFlag(
    'FT_6262_REACT_APPROVAL_ROUTING'
  )
  const INT_1976_MIGRATE_MANAGE_USERS_AND_PERMISSIONS = useFeatureFlag(
    'INT_1976_MIGRATE_MANAGE_USERS_AND_PERMISSIONS'
  )

  return (
    <Switch>
      <ProtectedSettingsRoute
        path={brp('SettingsManageFinancialAccountPage')}
        component={SettingsManageFinancialAccountPage}
        permissions={[Permissions.SETTINGS_MANAGE_FINANCIAL_ACCOUNT]}
        featureSwitches={['bill_pay']}
      />

      <ProtectedSettingsRoute
        path={brp('SettingsManageBankAccountsPage')}
        component={SettingsManageBankAccountsPage}
        permissions={[Permissions.SETTINGS_MANAGE_LINKED_BANK_ACCOUNTS]}
        featureSwitches={['bill_pay', 'enable_direct_debit']}
        featureFlags={['FT_5912_DIRECT_DEBIT_PAYMENTS']}
      />

      <ProtectedSettingsRoute
        path={brp('SpendingCardCustomFieldsSettingsPage')}
        component={SpendingCardCustomFieldsSettingsPage}
        permissions={[Permissions.SPENDING_CARD_MANAGE_CARD]}
      />

      <ProtectedSettingsRoute
        path={brp('BudgetCategoryManagementRouter')}
        component={BudgetCategoryManagementRouter}
      />

      <ProtectedSettingsRoute
        path={'/settings/budget-categories'}
        component={BudgetCategoryManagementRouter}
        featureFlags={['FT_5817_ENABLE_BUDGETS_REDESIGN']}
      />

      <ProtectedSettingsRoute
        path='/settings/import'
        component={SettingsImportPage}
        permissions={[Permissions.SETTINGS_IMPORT_DATA]}
      />

      <ProtectedSsoRoute
        path='/settings/sso'
        component={SsoConfigurationPage}
        navigationPermissions={['settings']}
      />

      <ProtectedSettingsRoute
        path='/settings/approver-view-access'
        component={ApproverOverwatchView}
      />

      <ProtectedSettingsRoute
        path={brp('SettingsSupportAccessPage')}
        component={SupportAccessPage}
        permissions={[Permissions.SETTINGS_GRANT_SUPPORT_ACCESS_CONSENT]}
      />

      <ProtectedSettingsRoute
        path={brp('SettingsBillFormPage')}
        component={SettingsBillFormPage}
        permissions={[Permissions.SETTINGS_CUSTOMIZE_BILL_COSTS]}
      />

      <ProtectedSettingsRoute
        path={brp('SettingsExpenseFormPage')}
        component={SettingsExpenseFormPage}
        permissions={[Permissions.SETTINGS_CUSTOMIZE_EXPENSE_FORM]}
      />

      <ProtectedSettingsRoute
        path={brp('ContractSettingsPage')}
        component={ContractSettingsPage}
        permissions={[Permissions.SETTINGS_MANAGE_CONTRACTS]}
      />

      <ProtectedSettingsRoute
        path={brp('PunchOutGroupPage', {
          supplier: ':supplier',
          group: ':group',
        })}
        component={PunchOutGroupPage}
        permissions={[Permissions.SETTINGS_MANAGE_INTEGRATION]}
        featureSwitches={['punchout']}
      />

      <ProtectedSettingsRoute
        path={brp('PunchOutSuppliersPage', { supplier: ':supplier?' })}
        component={PunchOutSuppliersPage}
        permissions={[Permissions.SETTINGS_MANAGE_INTEGRATION]}
      />

      <ProtectedRoute
        path={brp('SettingsMFAPreferencesPage')}
        component={MFAPreferencesPage}
        navigationPermissions={['settings']}
      />

      {shouldRedirectVendorApprovalRouting && (
        <Redirect
          path={brp('SettingsApprovalRoutingPage', {
            tab: ApprovalRoutingPageTabTypes.VENDORS,
          })}
          to={brp('SettingsApprovalRoutingPage', {
            tab: ApprovalRoutingPageTabTypes.REQUESTS,
          })}
        />
      )}

      <ProtectedSettingsRoute
        path={brp('SettingsApprovalRoutingPage', { tab: ':tab' })}
        component={ApprovalRoutingPage}
        permissions={[Permissions.SETTINGS_APPROVAL_ROUTING]}
        featureFlags={['FT_6262_REACT_APPROVAL_ROUTING']}
      />

      {FT_6262_REACT_APPROVAL_ROUTING && (
        <Redirect
          path={brp('SettingsApprovalRoutingPage', {
            tab: '',
          })}
          to={brp('SettingsApprovalRoutingPage', {
            tab: ApprovalRoutingPageTabTypes.REQUESTS,
          })}
        />
      )}

      {!FT_6262_REACT_APPROVAL_ROUTING && (
        <Redirect
          path={brp('SettingsApprovalRoutingPage', {
            tab: '',
          })}
          to='/#/settings/approvalrouting'
        />
      )}

      <ProtectedSettingsRoute
        path={brp('SettingsManageUsersPage', { status: ':status' })}
        component={ManageUsersPage}
        permissions={[Permissions.SETTINGS_MANAGE_USERS]}
        featureFlags={['INT_1976_MIGRATE_MANAGE_USERS_AND_PERMISSIONS']}
      />

      <ProtectedSettingsRoute
        path={brp('UserDetailsPage', { userId: ':userId' })}
        component={UserDetailsPage}
        permissions={[Permissions.SETTINGS_MANAGE_USERS]}
        featureFlags={['INT_1976_MIGRATE_MANAGE_USERS_AND_PERMISSIONS']}
      />

      {!INT_1976_MIGRATE_MANAGE_USERS_AND_PERMISSIONS && (
        <Redirect
          path={url('SettingsManageRolesPermissionsPage')}
          to='/#/settings/rolemanagement'
        />
      )}

      <ProtectedSettingsRoute
        path={brp('SettingsManageRolesPermissionsPage')}
        component={SettingsManageRolesPermissionsPage}
        permissions={[Permissions.SETTINGS_MANAGE_ROLES]}
        featureFlags={['INT_1976_MIGRATE_MANAGE_USERS_AND_PERMISSIONS']}
      />

      <ProtectedSettingsRoute
        exact
        path={brp('SettingsPage')}
        component={SettingsPage}
      />

      <Route path='*' render={() => <Redirect to='/' />} />
    </Switch>
  )
}
