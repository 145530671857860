import { type Entitlement } from '@webapp/core/types/Entitlement'
import { type EntitlementApiModel } from '../api-models/EntitlementApiModel'

export const mapEntitlement = (
  entitlementData: EntitlementApiModel
): Entitlement => ({
  purchasing: entitlementData.purchasing,
  expenses: entitlementData.expenses,
  ap: entitlementData.ap,
  spendingCards: entitlementData.spending_cards,
  contracts: entitlementData.contracts,
  budgets: entitlementData.budgets,
})
