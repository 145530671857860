import { type AppModuleConfig } from '@webapp/app/config/useModuleConfig'
import { type FeatureFlags } from '@webapp/core/services/FeatureFlag'
import { type IGlobalState } from '@webapp/core/stores/global'
import { type FeatureSwitches, type SetupProgress } from '@webapp/models'
import {
  type NavigationHeader,
  NavigationHeadersLegacy,
  type INavigationItem,
  type NavigationHeaderLegacy,
  NavigationHeaders,
} from '../types'

export const flattenNotificationsCount = (count) => {
  const finalCounts = {}

  for (const key in count) {
    if (key && typeof count[key] === 'object') {
      for (const innerkey in count[key]) {
        if (innerkey) {
          finalCounts[`${key}_${innerkey}`] = count[key][innerkey]
        }
      }
    } else {
      finalCounts[`${key}`] = count[key]
    }
  }

  return finalCounts
}

export const getAppStatusMeta = (
  applicationStatus: IGlobalState['applicationStatus'],
  role: IGlobalState['role']
) => {
  const { lock_mode: isTrialExpired, trial_mode: isTrialMode } =
    applicationStatus
  const isAdmin = role?.name === 'EKA'

  return {
    isTrialExpired,
    isTrialMode,
    isAdmin,
  }
}

export const filterByApplicationStatus =
  (
    applicationStatus: IGlobalState['applicationStatus'],
    role: IGlobalState['role']
  ) =>
  (item: INavigationItem) => {
    const { isAdmin, isTrialMode, isTrialExpired } = getAppStatusMeta(
      applicationStatus,
      role
    )

    if (isAdmin) return true

    // Dashboard is always visible
    if (item.id === 'dashboard') return true

    // In non-trial mode, show items
    if (!isTrialMode) return true

    // In active trial mode, show items
    if (isTrialMode && !isTrialExpired) return true

    return false
  }

export const filterByCondition =
  ({
    featureFlags,
    setupProgress,
    entitlement,
  }: {
    featureFlags?: Partial<FeatureSwitches> & Partial<FeatureFlags>
    setupProgress?: SetupProgress
    entitlement?: Partial<AppModuleConfig['navigation']>
  }) =>
  (item: INavigationItem) => {
    if (item.condition && typeof item.condition === 'function') {
      if (!item.condition({ featureFlags, setupProgress, entitlement }))
        return false
    }

    if (item.children) {
      item.children = item.children.filter((childNav) => {
        if (childNav.condition && typeof childNav.condition === 'function') {
          return childNav.condition({
            featureFlags,
            setupProgress,
            entitlement,
          })
        }

        return true
      })

      if (item.children.length) {
        const filteredChildrenID = item.children.reduce((prev, curr) => {
          if (!curr.isSubheader) {
            prev.push(curr.id)
          }
          return prev
        }, [])

        item.children = item.children.filter((childNav) => {
          if (!childNav.isSubheader || !childNav.links) {
            return true
          }

          return childNav.links.some((link) => {
            return filteredChildrenID.includes(link)
          })
        })

        if (item.children.every((childNav) => childNav.isSubheader === true)) {
          item.children = []
        }
      }
    }

    if (item.children && !item.children.length) {
      return false
    }
    return true
  }

export const filterByPermissions =
  (permissions: string[]) => (item: INavigationItem) => {
    if (item.url && item.id) {
      if (!permissions.includes(item.id)) {
        return false
      }
    }

    if (item.children) {
      item.children = item.children.filter((childNav) => {
        if (childNav.url) {
          if (!permissions.includes(childNav.id)) {
            return false
          }
        }

        if (childNav.links && childNav.links.length) {
          return childNav.links.some((link) => permissions.includes(link))
        }

        return true
      })

      if (item.children.every((childNav) => childNav.isSubheader === true)) {
        item.children = []
      }
    }

    if (item.children && !item.children.length) {
      return false
    }

    return true
  }

const getNavigationSection = (
  sectionHeaders: NavigationHeader[] | NavigationHeaderLegacy[],
  filteredNavigation: INavigationItem[]
) => {
  const returnArray = []
  sectionHeaders.forEach((header) => {
    const item = filteredNavigation.find((navItem) => navItem.id === header)
    if (item) {
      returnArray.push(item)
    }
  })

  return returnArray
}

export const getExpenseSection = (
  filteredNavigation: INavigationItem[]
): INavigationItem[] => {
  return getNavigationSection(
    [
      NavigationHeaders.EXPENSE_REQUESTS,
      NavigationHeaders.TRAVEL_REQUESTS,
      NavigationHeaders.SPENDING_CARD,
    ],
    filteredNavigation
  )
}

export const getPurchaseSection = (
  filteredNavigation: INavigationItem[]
): INavigationItem[] => {
  return getNavigationSection(
    [
      NavigationHeaders.ORDER_REQUESTS,
      NavigationHeaders.PURCHASE_AGREEMENT_REQUESTS,
      NavigationHeaders.PROCURE,
      NavigationHeaders.RECEIVE,
    ],
    filteredNavigation
  )
}

export const getSpendSection = (
  filteredNavigation: INavigationItem[]
): INavigationItem[] => {
  return getNavigationSection(
    [
      NavigationHeaders.PAYMENT_LEGACY,
      NavigationHeaders.FINANCIAL_ACCOUNT,
      NavigationHeaders.ACCOUNTS_PAYABLE,
    ],
    filteredNavigation
  )
}

export const getAnalyticsSection = (
  filteredNavigation: INavigationItem[]
): INavigationItem[] => {
  return getNavigationSection(
    [NavigationHeaders.REPORTS, NavigationHeaders.SPEND_DASHBOARD],
    filteredNavigation
  )
}

/**
 *
 * @deprecated - remove with FT_7540_REORDER_NAVIGATION
 */
export const getProcureSection = (
  filteredNavigation: INavigationItem[]
): INavigationItem[] => {
  return getNavigationSection(
    [
      NavigationHeadersLegacy.REQUEST,
      NavigationHeadersLegacy.APPROVE,
      NavigationHeadersLegacy.PROCURE,
      NavigationHeadersLegacy.RECEIVE,
    ],
    filteredNavigation
  )
}

/**
 *
 * @deprecated - remove with FT_7540_REORDER_NAVIGATION
 */
export const getSpendSectionLegacy = (
  filteredNavigation: INavigationItem[]
): INavigationItem[] => {
  return getNavigationSection(
    [
      NavigationHeadersLegacy.SPENDING_CARD,
      NavigationHeadersLegacy.PAYMENT_LEGACY,
      NavigationHeadersLegacy.ACCOUNTING,
    ],
    filteredNavigation
  )
}

/**
 *
 * @deprecated - remove with FT_7540_REORDER_NAVIGATION
 */
export const getManageSection = (
  filteredNavigation: INavigationItem[]
): INavigationItem[] => {
  return getNavigationSection(
    [
      NavigationHeadersLegacy.REPORTS,
      NavigationHeadersLegacy.DATA_AND_INSIGHTS,
    ],
    filteredNavigation
  )
}
