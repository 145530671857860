export class RepoError<T = any> extends Error {
  name = 'RepoError'

  code: Nullable<string>
  status: Nullable<number>
  message: string
  response: T

  constructor({
    code,
    status,
    message,
    response,
  }: {
    code: Nullable<string>
    status: number
    message: string
    response: T
  }) {
    super(message)

    this.code = code
    this.status = status || null
    this.message = message || ''
    this.response = response

    Object.setPrototypeOf(this, RepoError.prototype)
    Error.captureStackTrace(this, this.constructor)
  }
}
