import * as Factory from 'factory.ts'

import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import {
  PermissionCategory,
  type PermissionCategoryConstructor,
} from './PermissionCategory'
import { GeneralPermissionCategories, Permissions } from '../enums'

export const PermissionCategoryConstructorFactory =
  Factory.Sync.makeFactory<PermissionCategoryConstructor>({
    id: GeneralPermissionCategories.APPROVE,
    permissions: [
      {
        id: 1,
        key: Permissions.APPROVE_ORDER,
        canAssignToPrimaryCategories: false,
        canAssignToSecondaryCategories: false,
      },
      {
        id: 2,
        key: Permissions.APPROVE_EXPENSEREPORT,
        canAssignToPrimaryCategories: true,
        canAssignToSecondaryCategories: false,
      },
      {
        id: 3,
        key: Permissions.APPROVE_TRAVEL,
        canAssignToPrimaryCategories: true,
        canAssignToSecondaryCategories: false,
      },
    ],
  })

export const PermissionCategoryFactory = EntityFactory(
  PermissionCategory,
  PermissionCategoryConstructorFactory
)
