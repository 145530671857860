import {
  width,
  minWidth,
  maxWidth,
  type SizingProps,
} from '@material-ui/system'
import styled from 'styled-components'

interface ISpaceItemStyledProps
  extends Pick<SizingProps, 'width' | 'maxWidth' | 'minWidth'> {
  flex?: boolean
  grow?: number
  shrink?: number
}

export const SpaceItemStyled = styled.div<ISpaceItemStyledProps>`
  box-sizing: border-box;

  ${(props) => props.grow && `flex-grow: ${props.grow};`}
  ${(props) => props.shrink && `flex-shrink: ${props.shrink};`}
  ${(props) => props.flex && `display: flex;`}

  ${width}
  ${minWidth}
  ${maxWidth}
`
SpaceItemStyled.displayName = 'SpaceItemStyled'
