import { type Enum } from '@webapp/core/types/Enum'

export const AccountCodeTypes = {
  ASSET: 0,
  LIABILITY: 1,
  EXPENSE: 2,
  INCOME: 3,
  EQUITY: 4,
  OTHER: 5,
} as const

export type AccountCodeTypeEnum = Enum<typeof AccountCodeTypes>
