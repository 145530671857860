import * as Factory from 'factory.ts'
import faker from 'faker'

import { type BudgetCategoryApiModel } from '../BudgetCategoryApiModel'

export const BudgetCategoryApiModelStub =
  Factory.Sync.makeFactory<BudgetCategoryApiModel>({
    approved: Factory.each(() => faker.random.number().toString()),
    attributes: {
      departments: [],
      account_codes: [],
    },
    budget: null,
    end_datetime: Factory.each(() => faker.date.future().toISOString()),
    id: Factory.each(() => faker.random.number()),
    interval: null,
    invoiced: Factory.each(() => faker.random.number().toString()),
    name: Factory.each(() => faker.random.word()),
    pending: Factory.each(() => faker.random.number().toString()),
    purchased: Factory.each(() => faker.random.number().toString()),
    received: Factory.each(() => faker.random.number().toString()),
    start_datetime: Factory.each(() => faker.date.past().toISOString()),
    uuid: Factory.each(() => faker.random.uuid()),
    variance: null,
    variance_percentage: null,
  })
