import { type UserRoleApiModel } from '@webapp/platform/iam/data-access/api-models/UserRoleApiModel'
import { type UserRoleConstructor } from '@webapp/platform/iam/entities/UserRole'

export const mapUserRoleApiModel = (
  role: UserRoleApiModel
): UserRoleConstructor => ({
  id: role.id,
  name: role.name,
  type: role.type,
})
