import * as Factory from 'factory.ts'
import faker from 'faker'

import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import {
  BudgetCategory,
  type BudgetCategoryConstructorData,
} from './BudgetCategory'
import { BudgetIntervalTypes } from '../enums/BudgetIntervalTypes'

export const BudgetCategoryConstructorFactory =
  Factory.Sync.makeFactory<BudgetCategoryConstructorData>({
    id: Factory.each(() => faker.random.number()),
    uuid: Factory.each(() => faker.random.uuid()),
    name: Factory.each(() => faker.random.word()),
    amount: Factory.each(() => faker.random.number().toString()),
    interval: Factory.each(() => BudgetIntervalTypes.MONTHLY),
    secondaryCategories: [],
    accountCodes: [],
    startDate: Factory.each(() => faker.date.recent().toISOString()),
    endDate: Factory.each(() => faker.date.recent().toISOString()),
    approvedSpend: Factory.each(() => faker.random.number().toString()),
    invoicedSpend: Factory.each(() => faker.random.number().toString()),
    receivedSpend: Factory.each(() => faker.random.number().toString()),
    pendingSpend: Factory.each(() => faker.random.number().toString()),
    purchasedSpend: Factory.each(() => faker.random.number().toString()),
    varianceAmount: Factory.each(() => faker.random.number().toString()),
    variancePercentage: Factory.each(() => faker.random.number().toString()),
  })

export const BudgetCategoryFactory = EntityFactory(
  BudgetCategory,
  BudgetCategoryConstructorFactory
)
