import {
  SecondaryCategory,
  type SecondaryCategoryConstructor,
} from '@webapp/core/entities/internal/SecondaryCategory'
import { AccountCode, type AccountCodeConstructor } from '../AccountCode'

export interface AccountConstructor {
  id: number
  active: boolean
  accountCode: AccountCodeConstructor
  secondaryCategory: Nullable<SecondaryCategoryConstructor>
}

export class Account {
  id: number
  active: boolean
  accountCode: AccountCode
  secondaryCategory: Nullable<SecondaryCategory>

  constructor(data: AccountConstructor) {
    this.id = data.id
    this.active = data.active
    this.accountCode = new AccountCode(data.accountCode)
    this.secondaryCategory = data.secondaryCategory
      ? new SecondaryCategory(data.secondaryCategory)
      : null
  }
}
