import * as React from 'react'

import { useSelector } from 'react-redux'
import { Link, type LinkProps } from 'react-router-dom'

import { makeSelectRenderedBy } from '@webapp/core/stores/global/selectors'

export const ConnectedLink = ({
  to,
  ...otherProps
}: React.PropsWithChildren<LinkProps>) => {
  const renderedBy = useSelector(makeSelectRenderedBy())
  const href = typeof to === 'string' ? to : to.pathname

  switch (renderedBy) {
    case 'react':
      return <Link to={to} {...otherProps} />
    case 'angular':
      return (
        <a
          href={href}
          {...(otherProps as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
        />
      )
  }
}
ConnectedLink.displayName = 'ConnectedLink'
