import { type RepoFilterParams } from '@webapp/infrastructure/interfaces/repositories/RepoFilterParams'

export const formatRepoFiltersToApiFilters = (params?: RepoFilterParams) => {
  if (!params) {
    return {}
  }

  const { pageSize, page, orderBy, search, type, ...otherParams } = params

  return {
    page_size: pageSize,
    page,
    order_by: orderBy,
    search,
    type,
    ...otherParams,
  }
}
