import { defineMessages } from 'react-intl'

const scope = `procurify.components.AppHeader`

export default defineMessages({
  accountBtnText: {
    id: `${scope}.accountBtnText`,
    defaultMessage: `My account`,
  },
  logoutBtnText: {
    id: `${scope}.logoutBtnText`,
    defaultMessage: `Log out`,
  },
  userVoiceBtnText: {
    id: `${scope}.userVoiceBtnText`,
    defaultMessage: `What should we add next?`,
  },
  userVoiceBtnTitle: {
    id: `${scope}.userVoiceBtnTitle`,
    defaultMessage: `User Voice`,
  },
  impersonateUserPlaceholderText: {
    id: `${scope}.impersonateUserPlaceholderText`,
    defaultMessage: `Select a user`,
  },
  domainSwitchPlaceholderText: {
    id: `${scope}.domainSwitchPlaceholderText`,
    defaultMessage: `Select a domain`,
  },
  supportBtnText: {
    id: `${scope}.supportBtnText`,
    defaultMessage: `Talk to Support`,
  },
  helpCentreBtnText: {
    id: `${scope}.helpCentreBtnText`,
    defaultMessage: `HELP CENTRE`,
  },
  quickRequestBtnTitle: {
    id: `${scope}.quickRequestBtnTitle`,
    defaultMessage: `Request`,
  },
  quickRequestBtnTitleV2: {
    id: `${scope}.quickRequestBtnTitleV2`,
    defaultMessage: `New`,
  },
  quickRequestOrderBtnText: {
    id: `${scope}.quickRequestOrderBtnText`,
    defaultMessage: `Order`,
  },
  quickRequestExpenseBtnText: {
    id: `${scope}.quickRequestExpenseBtnText`,
    defaultMessage: `Expense`,
  },
  quickRequestTravelBtnText: {
    id: `${scope}.quickRequestTravelBtnText`,
    defaultMessage: `Travel`,
  },
  quickRequestFundBtnText: {
    id: `${scope}.quickRequestFundBtnText`,
    defaultMessage: `Fund`,
  },
  quickRequestPhysicalCardFundBtnText: {
    id: `${scope}.quickRequestPhysicalCardFundBtnText`,
    defaultMessage: `Physical card funds`,
  },
  quickRequestVirtualCardBtnText: {
    id: `${scope}.quickRequestVirtualCardBtnText`,
    defaultMessage: `Virtual card`,
  },
  quickRequestBillBtnText: {
    id: `${scope}.quickRequestBillBtnText`,
    defaultMessage: `Bill`,
  },
  quickRequestExpenseBillBtnText: {
    id: `${scope}.quickRequestExpenseBillBtnText`,
    defaultMessage: `Expense bill`,
  },
  quickRequestOrderBtnTitle: {
    id: `${scope}.quickRequestOrderBtnTitle`,
    defaultMessage: `New order request`,
  },
  quickRequestExpenseBtnTitle: {
    id: `${scope}.quickRequestExpenseBtnTitle`,
    defaultMessage: `New expense request`,
  },
  quickRequestTravelBtnTitle: {
    id: `${scope}.quickRequestTravelBtnTitle`,
    defaultMessage: `New travel request`,
  },
  quickRequestFundBtnTitle: {
    id: `${scope}.quickRequestFundBtnTitle`,
    defaultMessage: `New fund request`,
  },
  quickRequestPhysicalCardFundBtnTitle: {
    id: `${scope}.quickRequestPhysicalCardFundBtnTitle`,
    defaultMessage: `New physical card fund request`,
  },
  quickRequestVirtualCardBtnTitle: {
    id: `${scope}.quickRequestVirtualCardBtnTitle`,
    defaultMessage: `New virtual card request`,
  },
  quickRequestBillBtnTitle: {
    id: `${scope}.quickRequestBillBtnTitle`,
    defaultMessage: `New bill`,
  },
  quickRequestExpenseBillBtnTitle: {
    id: `${scope}.quickRequestExpenseBillBtnTitle`,
    defaultMessage: `New expense bill`,
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: `Help`,
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: `Notifications`,
  },
  appLogoLabel: {
    id: `${scope}.appLogoLabel`,
    defaultMessage: `Procurify Logo`,
  },
  approvalDelegatedLabel: {
    id: `${scope}.approvalDelegatedLabel`,
    defaultMessage: `Approval delegated`,
  },
})
