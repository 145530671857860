import * as React from 'react'

import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/app/components'
import { makeSelectFeatureSwitches } from '@webapp/core/stores/global'
import { useFeatureFlag } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { APIApplicationPage } from '@webapp/pages/APIApplicationPage'
import { Dynamics365BusinessCentralPage } from '@webapp/pages/Dynamics365BusinessCentralPage'
import { IntegrationMergePage } from '@webapp/pages/IntegrationMergePage'
import { IntegrationNetsuitePage } from '@webapp/pages/IntegrationNetsuitePage'
import { IntegrationPlatformListPage } from '@webapp/pages/IntegrationPlatformListPage'
import { NetsuitePage } from '@webapp/pages/NetsuitePage'
import { QboPage } from '@webapp/pages/QboPage'
import { SageIntacctPage } from '@webapp/pages/SageIntacctPage'
import { Permissions } from '@webapp/platform/iam'

const IntegrationsRoute = ({ path }) => {
  return (
    <ProtectedRoute
      exact
      path={path}
      component={IntegrationPlatformListPage}
      permissions={[Permissions.SETTINGS_MANAGE_INTEGRATION]}
    />
  )
}

export const IntegrationsRouter = () => {
  const { brp } = useAppRoutes()

  const { api_application_setup: showApplicationSetup } = useSelector(
    makeSelectFeatureSwitches()
  )

  const PLAT_1923_MERGE_QBO = useFeatureFlag('PLAT_1923_MERGE_QBO')

  return (
    <Switch>
      <Route
        exact
        path={brp('NetsuitePage')}
        component={IntegrationNetsuitePage}
      />

      <Route path={brp('NetsuiteBillsPage')} component={NetsuitePage} />

      <Route
        path={brp('QboPage')}
        component={PLAT_1923_MERGE_QBO ? IntegrationMergePage : QboPage}
      />

      <Route path={brp('SageIntacctPage')} component={SageIntacctPage} />

      <Route
        path={brp('Dynamics365BCPage')}
        component={Dynamics365BusinessCentralPage}
      />

      {showApplicationSetup && (
        <ProtectedRoute
          path={brp('ProcurifyApiPage')}
          component={APIApplicationPage}
          navigationPermissions={['settings']}
        />
      )}

      <IntegrationsRoute path={brp('IntegrationsPage')} />
    </Switch>
  )
}
