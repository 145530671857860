import {
  type AccountCodeApiModel,
  type AccountCodeConstructor,
} from '@webapp/platform/finance'

export const mapAccountCodeApiModel = ({
  account_type,
  code,
  description,
  id,
}: AccountCodeApiModel): AccountCodeConstructor => ({
  id,
  code,
  description,
  type: account_type,
})
