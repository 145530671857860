import { type IEntitlementRepository } from '@webapp/core/business-logic/interfaces/IEntitlementRepository'
import { EntitlementRepositoryValidationError } from '@webapp/core/data-access/repositories/EntitlementRepository'
import { type Entitlement } from '@webapp/core/types/Entitlement'
import { EntitlementServiceError } from './errors'
import { type EntitlementAccessConfig } from './types'

export const ENTITLEMENT_ACCESS_CONFIG_DEFAULTS = {
  module: {
    angular: {
      dashboard: {
        expense: { enabled: false },
        order: { enabled: false },
        travel: { enabled: false },
      },
    },
    purchasing: {
      enabled: false,
    },
    expense: {
      enabled: false,
    },
    ap: {
      everythingButExpense: {
        enabled: false,
      },
      expenseAp: {
        enabled: false,
      },
    },
    contracts: {
      enabled: false,
    },
    spendingCard: {
      enabled: false,
    },
    budgets: {
      enabled: false,
    },
  },
}

// temporary helper function - can be removed with FT_7508_IMPLEMENT_ENTITLEMENTS
export const setAllBooleansToTrue = <T extends object>(obj: T): T => {
  const result = { ...obj }

  Object.keys(result).forEach((key) => {
    const typedKey = key as keyof T

    if (typeof result[typedKey] === 'boolean') {
      result[typedKey] = true as any
    } else if (
      result[typedKey] !== null &&
      typeof result[typedKey] === 'object'
    ) {
      result[typedKey] = setAllBooleansToTrue(result[typedKey]) as any
    }
  })

  return result
}

export class EntitlementService {
  constructor(readonly repository: IEntitlementRepository) {}

  transformEntitlement = (
    entitlement: Entitlement
  ): EntitlementAccessConfig => {
    return {
      module: {
        angular: {
          dashboard: {
            expense: { enabled: entitlement.expenses.enabled },
            order: { enabled: entitlement.purchasing.enabled },
            travel: { enabled: entitlement.expenses.enabled },
          },
        },
        purchasing: {
          enabled: entitlement.purchasing.enabled,
        },
        expense: {
          enabled: entitlement.expenses.enabled,
        },
        spendingCard: {
          enabled: entitlement.spendingCards.enabled,
        },
        ap: {
          everythingButExpense: {
            enabled: entitlement.ap.enabled,
          },
          expenseAp: {
            enabled: entitlement.expenses.enabled,
          },
        },
        contracts: {
          enabled: entitlement.contracts.enabled,
        },
        budgets: {
          enabled: entitlement.budgets.enabled,
        },
      },
    }
  }

  async getEntitlement(): Promise<Entitlement> {
    try {
      return await this.repository.getEntitlement()
    } catch (error) {
      if (error instanceof EntitlementRepositoryValidationError) {
        throw new EntitlementServiceError(
          'Invalid entitlement configuration received',
          error as Error
        )
      }
      throw new EntitlementServiceError(
        'Failed to load entitlements',
        error as Error
      )
    }
  }

  async getEntitlementAccessConfig(): Promise<EntitlementAccessConfig> {
    const entitlementData = await this.getEntitlement()
    return this.transformEntitlement(entitlementData)
  }
}
