import {
  type CustomFieldValueConstructorData,
  type CustomFieldConstructorData,
} from '../../entities/internal/CustomField'
import {
  type CustomFieldValueApiModel,
  type CustomFieldApiModel,
} from '../api-models/CustomField'

export const mapCustomField = (
  customField: CustomFieldApiModel
): CustomFieldConstructorData => ({
  id: customField.id,
  name: customField.name,
  type: customField.field_type,
  choices: customField.field_choices,
  required: customField.is_required,
})

export const mapCustomFieldValue = (
  customFieldValue: CustomFieldValueApiModel
): CustomFieldValueConstructorData => ({
  id: customFieldValue.id,
  value: customFieldValue.value,
  field: mapCustomField(customFieldValue.field),
})
