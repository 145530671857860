import * as Factory from 'factory.ts'
import faker from 'faker'

import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import { PermissionConstructorFactory } from '@webapp/platform/iam/entities/Permission'
import { Role, type RoleConstructor } from '@webapp/platform/iam/entities/Role'
import { RoleType } from '../enums/RoleTypeEnum'

export const RoleConstructorFactory = Factory.Sync.makeFactory<RoleConstructor>(
  {
    id: Factory.each(() => faker.random.number()),
    name: Factory.each(() => faker.name.jobTitle()),
    type: Factory.each(() =>
      faker.random.arrayElement(Object.values(RoleType))
    ),
    permissions: [PermissionConstructorFactory.build()],
    userCount: Factory.each(() => faker.random.number()),
  }
)

export const RoleFactory = EntityFactory(Role, RoleConstructorFactory)
