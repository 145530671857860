import { type UserSettingApiModel } from '@webapp/platform/iam/data-access/api-models/UserSettingApiModel'
import { type UserPurchasePriceLimitConstructor } from '@webapp/platform/iam/entities/UserPurchasePriceLimit'

export const mapUserSettingApiModel = (
  userThresholdSettings: UserSettingApiModel
): UserPurchasePriceLimitConstructor => ({
  id: userThresholdSettings.id,
  dateModified: userThresholdSettings.modified_datetime,
  restrictEditableVariance: userThresholdSettings.restrict_editable_variance,
  editVarianceType: userThresholdSettings.edit_variance_type,
  varianceThresholdLimit: userThresholdSettings.variance_threshold_limit,
})
