import { ApiClient } from '@webapp/core/services/ApiClient'
import { LocalPersistentStorage } from '@webapp/core/services/LocalPersistentStorage'
import { EntitlementRepository } from './EntitlementRepository'
import { EntitlementRepositoryValidationError } from './errors'

const entitlementRepository = new EntitlementRepository({
  httpClient: ApiClient,
  persistentStorage: new LocalPersistentStorage({
    defaultTTL: LocalPersistentStorage.DEFAULT_TTL.WEEK,
  }),
})

export {
  entitlementRepository,
  EntitlementRepositoryValidationError,
  EntitlementRepository,
}
