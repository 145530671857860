import * as Factory from 'factory.ts'
import faker from 'faker'

import {
  ApproverDelegatee,
  type ApproverDelegateeConstructor,
} from '@webapp/core/entities/internal/ApproverDelegatee'
import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'

export const ApproverDelegateeConstructorFactory =
  Factory.Sync.makeFactory<ApproverDelegateeConstructor>({
    id: Factory.each(() => faker.random.number()),
    firstName: Factory.each(() => faker.name.firstName()),
    lastName: Factory.each(() => faker.name.lastName()),
    expectedReturnDate: Factory.each(() => faker.date.future().toISOString()),
  })

export const ApproverDelegateeFactory = EntityFactory(
  ApproverDelegatee,
  ApproverDelegateeConstructorFactory
)
