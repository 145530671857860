import { useMemo } from 'react'

import { useQuery } from 'react-query'

import { getOrderDetailsQueryKey } from '@webapp/modules/order/utils'
import { orderRepository } from '../../../data-access/repositories/OrderRepository'

export const useGetOrderPermissions = ({ orderId }: { orderId: string }) => {
  const { data } = useQuery({
    queryKey: getOrderDetailsQueryKey(orderId),
    queryFn: () => orderRepository.getDetails(orderId),
  })

  const permissions = useMemo(
    () => data?.metadata.permissions || null,
    [data?.metadata.permissions]
  )

  return permissions
}
