import * as Factory from 'factory.ts'
import faker from 'faker'

import { AccountCodeTypes } from '@webapp/platform/finance'
import { type AccountsApiModel } from '../AccountsApiModel'

const AccountCodeStub = Factory.Sync.makeFactory<
  AccountsApiModel['account_code']
>({
  id: Factory.each(() => faker.random.number()),
  code: Factory.each(() => faker.random.alphaNumeric(4)),
  code_length: Factory.each(() => 4),
  description: Factory.each(() => faker.commerce.productName()),
  account_type: AccountCodeTypes.OTHER,
  active: Factory.each(() => faker.random.boolean()),
  parent: null,
})

const DepartmentStub = Factory.Sync.makeFactory<AccountsApiModel['department']>(
  {
    id: Factory.each(() => faker.random.number()),
    branch: Factory.each(() => BranchStub.build()),
    external_id: Factory.each(() => faker.random.alphaNumeric(10)),
    name: Factory.each(() => faker.commerce.department()),
    active: Factory.each(() => faker.random.boolean()),
    punchout_email: null,
  }
)

const BranchStub = Factory.Sync.makeFactory<
  AccountsApiModel['department']['branch']
>({
  id: Factory.each(() => faker.random.number()),
  name: Factory.each(() => faker.company.companyName()),
  headquarter: Factory.each(() => faker.random.boolean()),
})

export const AccountsApiModelStub = Factory.Sync.makeFactory<AccountsApiModel>({
  id: Factory.each(() => faker.random.number()),

  account_code: Factory.each(() => AccountCodeStub.build()),
  department: Factory.each(() => DepartmentStub.build()),

  start_datetime: Factory.each(() => faker.date.past().toISOString()),
  end_datetime: Factory.each(() => faker.date.future().toISOString()),
  budget: Factory.each(() => faker.finance.amount()),
  budget_used_approved: Factory.each(() => faker.random.number()),
  budget_used_purchased: Factory.each(() => faker.random.number()),
  balance_approved: Factory.each(() => faker.finance.amount()),
  balance_purchased: Factory.each(() => faker.finance.amount()),
  active: Factory.each(() => faker.random.boolean()),
})
