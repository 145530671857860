import * as Factory from 'factory.ts'

import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import {
  Permission,
  type PermissionConstructor,
} from '@webapp/platform/iam/entities/Permission'
import { Permissions } from '../enums'

export const PermissionConstructorFactory =
  Factory.Sync.makeFactory<PermissionConstructor>({
    id: Factory.each((i) => i),
    key: Permissions.ADD_ORDER,
    canAssignToPrimaryCategories: false,
    canAssignToSecondaryCategories: false,
  })

export const PermissionFactory = EntityFactory(
  Permission,
  PermissionConstructorFactory
)
