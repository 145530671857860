import { useSelector } from 'react-redux'

import { makeSelectPermissions } from '@webapp/core/stores/global'
import { type PermissionEnum } from '@webapp/platform/iam'

export const useFilterPermissions = (
  permissions: PermissionEnum[]
): PermissionEnum[] => {
  const allPermissionsArray = useSelector(makeSelectPermissions()) || []
  const allPermissionsSet = new Set(
    allPermissionsArray.map((perm) => perm.codename)
  )

  return permissions.filter((permission) => allPermissionsSet.has(permission))
}
