import * as React from 'react'

import { useSelector } from 'react-redux'

import {
  makeSelectSegmentAnalyticsMetadata,
  makeSelectUser,
} from '@webapp/core/stores/global'
import { GlobalAnalytics } from './GlobalAnalytics'

export const ConnectedGlobalAnalytics = () => {
  const user = useSelector(makeSelectUser())
  const segmentAnalyticsMetadata = useSelector(
    makeSelectSegmentAnalyticsMetadata()
  )

  const shouldRender =
    user?.id &&
    segmentAnalyticsMetadata?.group &&
    segmentAnalyticsMetadata?.user

  return shouldRender ? (
    <GlobalAnalytics
      user={user}
      segmentAnalyticsMetadata={segmentAnalyticsMetadata}
    />
  ) : null
}
