import * as React from 'react'

import { useSelector } from 'react-redux'

import { toast } from '@webapp/components/Notification'
import { makeSelectDomain, makeSelectUser } from '@webapp/core/stores/global'
import { useProcurifyIntl } from '@webapp/hooks'
import { getChannelsForCurrentUserAndDomain } from '@webapp/services/SystemChannelManager/SystemChannelManager.service'
import { useSystemChannelSubscription } from '@webapp/services/SystemChannelManager/useSystemChannelSubscription'
import messages from '../messages'

interface BatchContractParsedEvent {
  batch_status: BatchContractParsedStatus
}

export enum BatchContractParsedStatus {
  FAIL = 0,
  PARTIAL_SUCCESS = 1,
  SUCCESS = 2,
}

const BATCH_CONTRACT_PARSED_EVENT = 'batch_contracts_parsed'

export const useSubscribeBatchContractProgress = () => {
  const intl = useProcurifyIntl()

  const user = useSelector(makeSelectUser())
  const domain = useSelector(makeSelectDomain())

  const { systemChannel } = getChannelsForCurrentUserAndDomain(domain, user.id)

  const channel = useSystemChannelSubscription({ channel: systemChannel })

  React.useEffect(() => {
    const callback = (data: BatchContractParsedEvent) => {
      switch (data.batch_status) {
        case BatchContractParsedStatus.FAIL:
          toast.error({
            title: intl.formatMessage(messages.batchContractParsedFailedTitle),
            children: intl.formatMessage(
              messages.batchContractParsedFailedMessage
            ),
          })
          break
        case BatchContractParsedStatus.PARTIAL_SUCCESS:
          toast.warning({
            title: intl.formatMessage(
              messages.batchContractParsedPartialSuccessTitle
            ),
            children: intl.formatMessage(
              messages.backContractParsedPartialSuccessMessage
            ),
          })
          break
        case BatchContractParsedStatus.SUCCESS:
          toast.success({
            title: intl.formatMessage(messages.batchContractParsedSuccessTitle),
            children: intl.formatMessage(
              messages.batchContractParsedSuccessMessage
            ),
          })
          break
      }
    }

    if (channel) {
      channel.bind(BATCH_CONTRACT_PARSED_EVENT, callback)
    }

    return () => {
      if (channel) {
        channel.unbind(BATCH_CONTRACT_PARSED_EVENT, callback)
      }
    }
  }, [channel])
}
