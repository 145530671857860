import * as React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { CardManagementAccountAPI } from '@webapp/api/CardManagementAccountAPI'
import { CardManagementBankAPI } from '@webapp/api/CardManagementBankAPI'
import {
  makeSelectIsPaySetUp,
  updateIsPaySetUp,
} from '@webapp/core/stores/global'
import { StripeBankStatusEnum } from '@webapp/models/enums/StripeBankStatus'

export const useIsSpendingCardsSetUp = () => {
  const dispatch = useDispatch()
  const isPaySetup = useSelector(makeSelectIsPaySetUp())

  // Setup status has not yet been checked if isPaySetup = null
  const [isSetupChecked, setIsSetupChecked] = React.useState<boolean>(
    isPaySetup !== null
  )
  const [isAccountVerified, setIsAccountVerified] =
    React.useState<boolean>(isPaySetup)

  React.useEffect(() => {
    if (isAccountVerified || isSetupChecked) return

    const requests = []

    requests.push(
      CardManagementAccountAPI.getAccounts().then((res) => res.data?.[0])
    )
    requests.push(
      CardManagementBankAPI.getBankAccounts().then((res) => res.data?.[0])
    )

    Promise.all(requests)
      .then(([account, bankAccount]) => {
        const accountVerified = !!account?.verification_completed
        const bankAccountVerified =
          bankAccount?.status === StripeBankStatusEnum.VERIFIED

        const isSetUp = accountVerified && bankAccountVerified

        dispatch(updateIsPaySetUp(isSetUp))
        setIsAccountVerified(isSetUp)
        setIsSetupChecked(true)
      })
      .catch(() => {
        setIsAccountVerified(false)
        setIsSetupChecked(true)
      })
  }, [])

  return {
    isLoading: !isSetupChecked,
    isSpendingCardsSetUp: isAccountVerified,
  }
}
