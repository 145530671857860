import { type Enum } from '@webapp/core/types/Enum'

export const SystemPreferenceKeys = {
  BUDGET_CORRECTION: 'budget-correction',
  CUSTOM_LABELS: 'custom-labels',
  EXPENSE_REIMBURSEMENT: 'expense-reimbursement',
  LOCALE: 'locale',
  MULTI_FACTOR_AUTHENTICATION: 'multi-factor-authentication',
  PO_PDF_CUSTOM_FIELDS_HIDDEN: 'po-pdf-custom-fields-hidden',
  REQUESTER_AGREEMENT_MANAGEMENT: 'requester-agreement-management',
} as const

export type SystemPreferenceKeysEnum = Enum<typeof SystemPreferenceKeys>
