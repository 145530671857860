import type * as Factory from 'factory.ts'

export const EntityFactory = <E, C>(
  Entity: new (constructor: C) => E,
  constructorFactory: Factory.Sync.Factory<C>
) => {
  return {
    build: (data: Factory.RecPartial<C> & Omit<C, keyof C> = {} as any) =>
      new Entity(constructorFactory.build(data)),
    buildList: (
      num: number,
      data: Factory.RecPartial<C> & Omit<C, keyof C> = {} as any
    ) => constructorFactory.buildList(num, data).map((d) => new Entity(d)),
  }
}
