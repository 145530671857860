import React from 'react'

import { Redirect, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/app/components'
import { useModuleConfig } from '@webapp/app/config/useModuleConfig'
import { useAppRoutes } from '@webapp/hooks'
import { SpendAnalyticsPage } from '@webapp/pages'

export const DataAndInsightsRouter = () => {
  const { url } = useAppRoutes()

  const {
    routes: { enableDataAndInsightsRoute },
  } = useModuleConfig()

  if (!enableDataAndInsightsRoute) return <Redirect to={url('DashboardPage')} />

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={url('SpendAnalyticsPage')}
        render={() => <SpendAnalyticsPage />}
        navigationPermissions={['data_and_insights__spend_insights']}
      />
    </Switch>
  )
}
