import * as React from 'react'

import { Redirect, Route, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/app'
import { useAppRoutes } from '@webapp/hooks'
import { OrderStatusPage } from '@webapp/pages'
import { Permissions } from '@webapp/platform/iam'
import { OrderDetailsPage } from './OrderDetailsPage'
import { OrderStatusPageStatuses } from './types'

const orderStatusFormat = Object.values(OrderStatusPageStatuses).join('|')

export const OrderRouter = () => {
  const { brp, url } = useAppRoutes()

  return (
    <Switch>
      {/* Order Status */}
      <Redirect
        exact
        from={brp('OrderStatusPage', {
          status: '',
        })}
        to={url('OrderStatusPage', {
          status: OrderStatusPageStatuses.PENDING,
        })}
      />

      <ProtectedRoute
        path={brp('OrderStatusPage', {
          status: `:status(${orderStatusFormat})`,
        })}
        component={OrderStatusPage}
        navigationPermissions={['request__order']}
      />

      {/* Order Details */}
      <ProtectedRoute
        path={brp('OrderDetailsPage', { uuid: ':uuid' })}
        component={OrderDetailsPage}
        permissions={[
          Permissions.ADD_ORDER,
          Permissions.PROCURE_ADD_PO,
          Permissions.ACCOUNTS_PAYABLE_ADD_BILL,
          Permissions.ACCOUNTS_PAYABLE_CREATE_PAYMENTS,
          Permissions.APPROVE_ORDER,
          /**
           * @legacy
           * remove with `legacy-payments` switch
           */
          Permissions.LEGACY_PAYMENT,
        ]}
      />

      {/* Fallback for /orders/ */}
      <Route
        path='*'
        render={() => (
          <Redirect
            to={url('OrderStatusPage', {
              status: OrderStatusPageStatuses.PENDING,
            })}
          />
        )}
      />
    </Switch>
  )
}
