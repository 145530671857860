import * as Factory from 'factory.ts'
import faker from 'faker'

import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import {
  SecondaryCategory,
  type SecondaryCategoryConstructor,
} from './SecondaryCategory'
import { PrimaryCategoryFactory } from '../PrimaryCategory'

export const SecondaryCategoryConstructorFactory =
  Factory.Sync.makeFactory<SecondaryCategoryConstructor>({
    id: Factory.each(() => faker.random.number()),
    name: Factory.each(() => faker.commerce.department()),
    primaryCategory: Factory.each(() => PrimaryCategoryFactory.build()),
  })

export const SecondaryCategoryFactory = EntityFactory(
  SecondaryCategory,
  SecondaryCategoryConstructorFactory
)
