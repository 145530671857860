import { type PermissionEnum } from '../enums'

export interface PermissionConstructor {
  id: number
  key: PermissionEnum
  canAssignToPrimaryCategories?: boolean
  canAssignToSecondaryCategories?: boolean
}

export class Permission {
  id: number
  key: PermissionEnum
  canAssignToPrimaryCategories?: boolean
  canAssignToSecondaryCategories?: boolean

  constructor(data: PermissionConstructor) {
    this.id = data.id
    this.key = data.key
    this.canAssignToPrimaryCategories = data.canAssignToPrimaryCategories
    this.canAssignToSecondaryCategories = data.canAssignToSecondaryCategories
  }
}
