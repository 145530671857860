import { type AxiosError, type AxiosInstance } from 'axios'

import {
  HttpError,
  type IHttpClient,
  type RequestConfig,
} from '@webapp/infrastructure/interfaces/services/IHttpClient'

export class AxiosHttpClient implements IHttpClient {
  constructor(private client: AxiosInstance) {
    const errorInterceptor = (error: AxiosError) => {
      throw new HttpError(
        error.response?.status || -1,
        error.response?.data || null
      )
    }

    client.interceptors.response.use(undefined, errorInterceptor)
  }

  get(url: string, config?: RequestConfig) {
    return this.client.get(url, config)
  }

  delete(url: string, config?: RequestConfig) {
    return this.client.delete(url, config)
  }

  head(url: string, config?: RequestConfig) {
    return this.client.head(url, config)
  }

  options(url: string, config?: RequestConfig) {
    return this.client.options(url, config)
  }

  post(url: string, data?: any, config?: RequestConfig) {
    return this.client.post(url, data, config)
  }

  put(url: string, data?: any, config?: RequestConfig) {
    return this.client.put(url, data, config)
  }

  patch(url: string, data?: any, config?: RequestConfig) {
    return this.client.patch(url, data, config)
  }
}
