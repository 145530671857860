import { formatRepoFiltersToApiFilters } from '@webapp/core/utils/repositories/formatRepoFiltersToApiFilters'
import { type RepoFilterParams } from '@webapp/infrastructure/interfaces/repositories/RepoFilterParams'
import { type RepoPaginatedResponse } from '@webapp/infrastructure/interfaces/repositories/RepoPaginatedResponse'
import { type IHttpClient } from '@webapp/infrastructure/interfaces/services/IHttpClient'
import { type IAccountRepository } from '@webapp/platform/finance/business-logic/interfaces/IAccountRepository'
import { type AccountConstructor } from '@webapp/platform/finance/entities/Account'
import { mapAccountsApiModel } from '../../mappers/mapAccountsApiModel'
import { type GetRequestableAccountsResponse } from '../../types/GetRequestableAccountsResponse'

interface Dependency {
  httpClient: IHttpClient
}

export class AccountRepository implements IAccountRepository {
  constructor(private readonly deps: Dependency) {}

  async getRequestableAccountsByDepartment(
    departmentId: number,
    params?: RepoFilterParams
  ): Promise<RepoPaginatedResponse<AccountConstructor>> {
    const res = await this.deps.httpClient.get<GetRequestableAccountsResponse>(
      `/api/v3/accounts/requestable/`,
      {
        params: {
          department: departmentId,
          ...formatRepoFiltersToApiFilters(params),
        },
      }
    )

    const accounts = res.data.data
    const metadata = res.data.metadata

    return {
      data: accounts.map((account) => mapAccountsApiModel(account)),
      pagination: {
        count: metadata?.pagination?.count || 0,
        page: metadata?.pagination?.current_page || 0,
        pageSize: metadata?.pagination?.page_size || 0,
        numPages: metadata?.pagination?.num_pages || 0,
      },
    }
  }
}
