import * as React from 'react'

import { useSelector } from 'react-redux'
import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { makeSelectFeatureSwitches } from '@webapp/core/stores/global'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import { BillListPageStatuses } from '@webapp/pages/BillListPage'
import messages from './messages'

export interface BillCreatePageParams {
  uuid?: uuid
}

const BillCreateLegacyViewChunk = React.lazy(() =>
  import('../containers/BillCreateView').then(({ BillCreateViewLegacy }) => ({
    default: BillCreateViewLegacy,
  }))
)

const BillCreateContainerChunk = React.lazy(() =>
  import('../containers/BillCreateContainer').then(
    ({ BillCreateContainer }) => ({
      default: BillCreateContainer,
    })
  )
)

export const BillCreatePage = (
  props: RouteComponentProps<BillCreatePageParams>
) => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()
  const { bill_received_only } = useSelector(makeSelectFeatureSwitches())

  if (!bill_received_only) {
    return (
      <Page
        pageTitle={intl.formatMessage(messages.billCreatePageTitle)}
        breadcrumbs={[
          {
            name: intl.formatMessage(messages.manageBillsPageTitle),
            link: url('BillListPage', {
              status: BillListPageStatuses.DRAFT,
            }),
          },
          { name: intl.formatMessage(messages.billCreatePageTitle) },
        ]}
      >
        <BillCreateContainerChunk billUUID={props.match.params?.uuid} />
      </Page>
    )
  }

  return (
    <React.Suspense fallback={null}>
      <BillCreateLegacyViewChunk {...props} />
    </React.Suspense>
  )
}
