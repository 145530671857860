import { defineMessages } from 'react-intl'

const scope = `procurify.budgets.components.OpenSpendTrackerSheetButton`

export default defineMessages({
  showSpendTrackerButtonLabel: {
    id: `${scope}.showSpendTrackerButtonLabel`,
    defaultMessage: 'Show Spend Tracker',
  },
})
