import * as React from 'react'

import { padding, tint } from 'polished'
import ReactSelect, {
  type ClearIndicatorProps,
  components as ReactSelectComponents,
  type DropdownIndicatorProps,
  type GroupBase,
  type MenuListProps,
  type StylesConfig,
  type ThemeConfig,
  useStateManager,
} from 'react-select'
import { type CreatableProps, useCreatable } from 'react-select/creatable'
import { useTheme } from 'styled-components'
import styled from 'styled-components'

import { ScrollCapture } from './utils/ScrollCapture'
import { Icon } from '../Icon'

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
  > {
    stickies?: (props: any) => JSX.Element[]
  }
}

export interface SelectValue<T = any> {
  label: string
  value: T
}

export interface CreatableSelectValue extends SelectValue {
  __isNew__: boolean
}

const IndicatorsContainer = (props) => {
  const { children, ...otherProps } = props

  const newChildren = children.slice()

  const [clearIndicator, LoadingIndicator, , dropdownIndicator] = newChildren

  return (
    <ReactSelectComponents.IndicatorsContainer {...otherProps}>
      {LoadingIndicator}
      {clearIndicator ? clearIndicator : dropdownIndicator}
    </ReactSelectComponents.IndicatorsContainer>
  )
}

const DropdownIndicator = (props: DropdownIndicatorProps<any>) => {
  const activeTheme = useTheme()

  return (
    <ReactSelectComponents.DropdownIndicator {...props}>
      <Icon
        icon={
          props.selectProps.menuIsOpen
            ? 'chevron-thick-up'
            : 'chevron-thick-down'
        }
        size={12}
        style={{
          color: activeTheme.colors.textPrimary,
          fontWeight: activeTheme.fontWeights.bold,
        }}
      />
    </ReactSelectComponents.DropdownIndicator>
  )
}

const ClearIndicator = (props: ClearIndicatorProps<any>) => {
  const activeTheme = useTheme()

  return (
    <ReactSelectComponents.ClearIndicator {...props}>
      <Icon
        icon='times'
        size={10}
        style={{
          color: activeTheme.colors.textPrimary,
          fontWeight: activeTheme.fontWeights.bold,
        }}
      />
    </ReactSelectComponents.ClearIndicator>
  )
}

const StickyOptionStyled = styled.div`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  && {
    ${(props) => padding(props.theme.spacing())};
    font-size: ${(props) => props.theme.typography.pxToRem(11)};
    cursor: pointer;

    & + & {
      border-top: 1px solid ${(props) => props.theme.colors.content.border};
    }

    &:hover {
      background-color: ${(props) => tint(0.75, props.theme.colors.darkGrey)};
    }
  }
`

const StickyContainerStyled = styled.div`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  border-top: 1px solid ${(props) => props.theme.colors.content.border};
`

const MenuList = (props: MenuListProps<any>) => {
  return (
    <React.Fragment>
      <ScrollCapture onBottomArrive={props.selectProps.onMenuScrollToBottom}>
        <ReactSelectComponents.MenuList {...props}>
          {props.children}
        </ReactSelectComponents.MenuList>
      </ScrollCapture>

      {props.selectProps.stickies && (
        <StickyContainerStyled>
          {props.selectProps.stickies({
            ...props,
            closeMenu: () =>
              props.setValue(props.getValue()[0], 'select-option'),
          })}
        </StickyContainerStyled>
      )}
    </React.Fragment>
  )
}

export const useSelectStylesAndTheme = <
  Option,
  IsMulti extends boolean = false,
>({
  error,
  removeValueMargin,
}: {
  error: ISelectProps['error']
  removeValueMargin: ISelectProps['removeValueMargin']
}): {
  styles: StylesConfig<Option, IsMulti, GroupBase<Option>>
  theme: ThemeConfig
} => {
  const activeTheme = useTheme()

  return {
    styles: {
      option: (base, state) => {
        return {
          ...base,
          fontSize: activeTheme.typography.pxToRem(12),
          color: activeTheme.colors.textPrimary,
          backgroundColor: state.isDisabled
            ? activeTheme.colors.form.field.disabled
            : base.backgroundColor,
        }
      },
      group: (base) => {
        return {
          ...base,
          paddingBottom: 0,
          paddingTop: activeTheme.typography.pxToRem(12),
        }
      },
      menuPortal: (base) => {
        return {
          ...base,
          zIndex: 9999,
        }
      },
      placeholder: (base) => {
        return {
          ...base,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          left: 8,
          right: 0,
          color: activeTheme.colors.text.placeholder,
          fontSize: activeTheme.typography.pxToRem(14),
          lineHeight: 1.2,
        }
      },
      singleValue: (base, state) => ({
        ...base,
        lineHeight: 1.2,
        fontSize: activeTheme.typography.pxToRem(14),
        marginTop: removeValueMargin ? 0 : -1,
        opacity: state.selectProps.menuIsOpen ? '0.3' : '1',
        color: state.isDisabled
          ? activeTheme.colors.textDisabled
          : activeTheme.colors.textPrimary,
      }),
      input: (base, state) => {
        return {
          ...base,
          fontSize: activeTheme.typography.pxToRem(14),
          input: {
            height: 'auto',
          },
        }
      },
      control: (base, state) => {
        return {
          ...base,
          boxShadow: 'none',
          minWidth: 100,
          backgroundColor: state.isDisabled
            ? activeTheme.colors.form.field.disabled
            : activeTheme.colors.white,
          borderColor: error
            ? state.theme.colors.danger
            : state.isFocused
              ? activeTheme.colors.content.border
              : state.theme.colors.neutral20,
          '&:hover': {
            borderColor: error
              ? state.theme.colors.danger
              : state.isFocused
                ? activeTheme.colors.content.border
                : state.theme.colors.neutral30,
          },
        }
      },
      container: (base, state) => {
        return {
          ...base,
          fontWeight: 'normal',
        }
      },
    },
    theme: (theme) => {
      return {
        ...theme,
        colors: {
          ...theme.colors,
          neutral20: activeTheme.colors.content.border,
          neutral30: activeTheme.colors.darkGrey,
          primary: activeTheme.colors.form.select.optionBackgroundActive,
          primary25: activeTheme.colors.form.select.optionBackgroundHover,
          primary50: activeTheme.colors.form.select.optionBackgroundHover,
          primary75: activeTheme.colors.form.select.optionBackgroundHover,
          bgDisabled: activeTheme.colors.form.field.disabled,
          bgPrimary: activeTheme.colors.white,
        },
        fontWeights: {
          ...activeTheme.fontWeights,
        },
      }
    },
  }
}

export interface ISelectProps<
  Option = any,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends CreatableProps<Option, IsMulti, Group> {
  stickies?: (props: any) => JSX.Element[]
  error?: boolean
  /**
   * Remove the margin-top: -1px from ValueContainer
   */
  removeValueMargin?: boolean
  creatable?: boolean
}

export const Select = <Option, IsMulti extends boolean = false>({
  className,
  components,
  error,
  removeValueMargin,
  styles = {},
  creatable,
  ...otherProps
}: ISelectProps<Option, IsMulti>) => {
  const { styles: defaultStyles, theme } = useSelectStylesAndTheme<
    Option,
    IsMulti
  >({
    error,
    removeValueMargin,
  })

  let selectProps = useStateManager(otherProps)

  if (creatable) {
    selectProps = useCreatable(selectProps)
  }

  return (
    <ReactSelect
      className={`react-select ${className}`}
      classNamePrefix='react-select'
      captureMenuScroll={false}
      components={{
        IndicatorsContainer,
        ClearIndicator,
        DropdownIndicator,
        MenuList,
        ...components,
      }}
      styles={{
        ...defaultStyles,
        ...styles,
      }}
      theme={theme}
      {...selectProps}
    />
  )
}

Select.StickyOption = StickyOptionStyled
