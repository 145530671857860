import { type PermissionEnum, Permissions } from '@webapp/platform/iam'
import { useCheckPermission } from '../useCheckPermission'
import { useFeatureFlag } from '../useFeatureFlag'
import { useFilterPermissions } from '../useFilterPermissions'

const AP_BILL_PERMISSIONS_HIERARCHY = {
  [Permissions.ACCOUNTS_PAYABLE_VIEW_BILL]: 0,
  [Permissions.ACCOUNTS_PAYABLE_EDIT_DRAFT_PENDING_DENIED_BILLS]: 1,
  [Permissions.ACCOUNTS_PAYABLE_EDIT_APPROVED_BILLS]: 2,
  [Permissions.ACCOUNTS_PAYABLE_DELETE_SUBMITTED_BILLS]: 3,
}

const AP_BILL_PERMISSIONS = Object.keys(
  AP_BILL_PERMISSIONS_HIERARCHY
) as PermissionEnum[]

const hasBillPermissionLevel = (
  requiredPermission: PermissionEnum,
  level: number
): boolean => {
  return level >= AP_BILL_PERMISSIONS_HIERARCHY[requiredPermission]
}

/**
 * Checks if the user is in "view-only" mode for bills.
 *
 * @returns {boolean} True if the user can only view bills without elevated permissions.
 */
const isViewOnly = (level: number): boolean => {
  return (
    level ===
    AP_BILL_PERMISSIONS_HIERARCHY[Permissions.ACCOUNTS_PAYABLE_VIEW_BILL]
  )
}

export const useBillsPermissions = () => {
  const CL_1058_AP_GRANULAR_PERMISSIONS = useFeatureFlag(
    'CL_1058_AP_GRANULAR_PERMISSIONS'
  )
  const availablePermissions = useFilterPermissions(AP_BILL_PERMISSIONS)
  const canAddBillLegacy = useCheckPermission(
    Permissions.ACCOUNTS_PAYABLE_ADD_BILL
  )

  if (!CL_1058_AP_GRANULAR_PERMISSIONS) {
    return {
      canViewOnlyBills: false,
      canViewBills: canAddBillLegacy,
      canEditPendingDraftDeniedBills: canAddBillLegacy,
      canEditApprovedBills: canAddBillLegacy,
      canDeleteSubmittedBills: canAddBillLegacy,
    }
  }

  const levels = availablePermissions
    .map((permission) => AP_BILL_PERMISSIONS_HIERARCHY[permission])
    .concat(-1) // default permissionless level if none exist
  const highestBillPermissionLevel = Math.max(...levels)

  return {
    canViewOnlyBills: isViewOnly(highestBillPermissionLevel),
    canViewBills: hasBillPermissionLevel(
      Permissions.ACCOUNTS_PAYABLE_VIEW_BILL,
      highestBillPermissionLevel
    ),
    canEditPendingDraftDeniedBills: hasBillPermissionLevel(
      Permissions.ACCOUNTS_PAYABLE_EDIT_DRAFT_PENDING_DENIED_BILLS,
      highestBillPermissionLevel
    ),
    canEditApprovedBills: hasBillPermissionLevel(
      Permissions.ACCOUNTS_PAYABLE_EDIT_APPROVED_BILLS,
      highestBillPermissionLevel
    ),
    canDeleteSubmittedBills: hasBillPermissionLevel(
      Permissions.ACCOUNTS_PAYABLE_DELETE_SUBMITTED_BILLS,
      highestBillPermissionLevel
    ),
  }
}
