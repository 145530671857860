import { useQueryUserDetails } from '@webapp/platform/iam/features/get-user-detailed-information/models/useQueryUserDetails'

export const useUserDetailsPage = ({ userId }: { userId: number }) => {
  const {
    data: { user },
  } = useQueryUserDetails({ userId })

  return {
    data: {
      user,
    },
  }
}
