import * as React from 'react'

import {
  Card,
  Divider,
  Space,
  SubText,
  Text,
  TransparentIconButton,
  WithTooltip,
} from '@procurify/ui'
import { FormattedDate } from 'react-intl'

import { FormattedTime } from '@webapp/core/components/FormattedTime'
import { useProcurifyIntl } from '@webapp/hooks'
import { getFullName } from '@webapp/utils/user'
import messages from './messages'
import { InboxNotificationStyled } from './styles/InboxNotificationStyled'
import { type Inbox } from '../../models'

export interface IInboxNotificationProps {
  notification?: Inbox
  action?: string | React.ReactElement
  onRead?: () => void
  metas?: React.ReactElement[] | React.ReactElement
  isReminder?: boolean
  hideLink?: boolean
  link?: string
}

export const InboxNotification = ({
  notification,
  action,
  metas,
  onRead,
  isReminder,
  hideLink,
  link = null,
}: IInboxNotificationProps) => {
  const intl = useProcurifyIntl()

  const showMarkAsRead = !!onRead
  const showLink = (!!notification.message.permalink && !hideLink) || link
  const showComment = !!notification.message.comment

  return (
    <InboxNotificationStyled read={false} isReminder={isReminder}>
      <Card>
        <Card.Content p={0.75}>
          <Space direction='horizontal' justify='space-between' size='xsmall'>
            <>
              <Text bold autoMargin={false}>
                {notification.message.userprofile
                  ? getFullName(notification.message.userprofile)
                  : 'Procurify'}
              </Text>
              {action && (
                <SubText bold>
                  <span className='action'>{action}</span>
                </SubText>
              )}

              <Space direction='horizontal' size='small'>
                <SubText color='textSecondary'>
                  <FormattedDate
                    day='numeric'
                    month='long'
                    year='numeric'
                    value={notification.updated_at}
                  />{' '}
                  <FormattedTime date={notification.updated_at} />
                </SubText>
                <SubText>{metas}</SubText>
              </Space>
            </>

            <Space direction='horizontal' size='xsmall'>
              {showMarkAsRead && (
                <WithTooltip title={intl.formatMessage(messages.markAsRead)}>
                  <TransparentIconButton
                    className='btn-markasread'
                    icon='checkmark'
                    onClick={onRead}
                    aria-label={intl.formatMessage(messages.markAsRead)}
                  />
                </WithTooltip>
              )}
              {showLink && (
                <WithTooltip title={intl.formatMessage(messages.goTo)}>
                  <a
                    href={link || notification.message.permalink}
                    className='btn-goto'
                  >
                    <TransparentIconButton
                      icon='link'
                      aria-label={intl.formatMessage(messages.goTo)}
                    />
                  </a>
                </WithTooltip>
              )}
            </Space>
          </Space>

          {showComment && (
            <>
              <Divider space={0.5} />

              <SubText>
                <span
                  dangerouslySetInnerHTML={{
                    __html: notification.message.comment,
                  }}
                />
              </SubText>
            </>
          )}
        </Card.Content>
      </Card>
    </InboxNotificationStyled>
  )
}

InboxNotification.displayName = 'InboxNotification'
