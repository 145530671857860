import * as React from 'react'

import { Redirect, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/app/components'
import { useModuleConfig } from '@webapp/app/config/useModuleConfig'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import {
  VendorApprovalDetailsPage,
  VendorApprovalListPage,
} from '@webapp/pages'
import { VendorApprovalListPageTabStatuses } from '@webapp/pages/VendorApprovalListPage'

const vendorApprovalStatusFormat = Object.values(
  VendorApprovalListPageTabStatuses
).join('|')

export const VendorRouter = () => {
  const { url, brp } = useAppRoutes()

  const {
    routes: { enablePurchasingRoutes },
  } = useModuleConfig()

  if (!enablePurchasingRoutes) return <Redirect to={url('DashboardPage')} />

  return (
    <Switch>
      <ProtectedRoute
        path={brp('VendorApprovalListPage', {
          status: `:status(${vendorApprovalStatusFormat})`,
        })}
        component={VendorApprovalListPage}
        navigationPermissions={['approval__vendor_request']}
        featureSwitches={['enable_vendor_approvals']}
      />

      <ProtectedRoute
        exact
        path={brp('VendorApprovalDetailsPage', {
          id: `:id`,
        })}
        component={VendorApprovalDetailsPage}
        navigationPermissions={['approval__vendor_request']}
        featureSwitches={['enable_vendor_approvals']}
      />

      <Redirect
        path={brp('VendorApprovalListPage', {
          status: '',
        })}
        to={brp('VendorApprovalListPage', {
          status: VendorApprovalListPageTabStatuses.PENDING,
        })}
      />
    </Switch>
  )
}
