import { mapLocation } from '@webapp/core/data-access/mappers/mapLocation'
import { type DetailedUserApiModel } from '@webapp/platform/iam/data-access/api-models/DetailedUserApiModel'
import { mapUserRoleApiModel } from '@webapp/platform/iam/data-access/mapper/mapUserRoleApiModel'
import { type DetailedUserConstructor } from '@webapp/platform/iam/entities/DetailedUser'

export const mapDetailedUserApiModel = (
  user: DetailedUserApiModel
): DetailedUserConstructor => ({
  id: user.id,
  authId: user.user,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  phone: user.phone,
  primaryCategory: user.location ? mapLocation(user.location) : null,
  secondaryCategory: user.department
    ? {
        id: user.department.id,
        name: user.department.name,
        primaryCategory: user.department.location
          ? mapLocation(user.department.location)
          : null,
      }
    : null,
  profileImage: user.profile_image,
  active: user.is_active,
  position: user.position,
  role: user.role ? mapUserRoleApiModel(user.role) : null,
  permissions: user.permissions.map((p) => ({
    id: p.id,
    key: p.codename,
    primaryCategoryIds: p.location_ids || [],
    secondaryCategoryIds: p.department_ids || [],
  })),
  approvalDelegatee: user.approval_delegatee
    ? {
        id: user.approval_delegatee.id,
        firstName: user.approval_delegatee.firstName,
        lastName: user.approval_delegatee.lastName,
        expectedReturnDate: user.expected_return_date || null,
      }
    : null,
})
