import { useSelector } from 'react-redux'

import {
  ENTITLEMENT_ACCESS_CONFIG_DEFAULTS,
  setAllBooleansToTrue,
} from '@webapp/core/services/Entitlement'
import {
  makeSelectEntitlementAccessConfig,
  makeSelectIsAppReady,
} from '@webapp/core/stores/global'
import { useFeatureFlag } from '@webapp/hooks'

export const useEntitlement = () => {
  const isAppReady = useSelector(makeSelectIsAppReady())
  const entitlement = useSelector(makeSelectEntitlementAccessConfig())
  const FT_7508_IMPLEMENT_ENTITLEMENTS = useFeatureFlag(
    'FT_7508_IMPLEMENT_ENTITLEMENTS'
  )

  if (!isAppReady) {
    return { ...ENTITLEMENT_ACCESS_CONFIG_DEFAULTS }
  }

  return FT_7508_IMPLEMENT_ENTITLEMENTS
    ? entitlement
    : setAllBooleansToTrue(ENTITLEMENT_ACCESS_CONFIG_DEFAULTS)
}
