import { mapAttachmentApiModel } from '@webapp/core/data-access/mappers/mapAttachment'
import { mapCurrency } from '@webapp/core/data-access/mappers/mapCurrency'
import { mapCustomFieldValue } from '@webapp/core/data-access/mappers/mapCustomField'
import { mapDepartment } from '@webapp/core/data-access/mappers/mapDepartment'
import { mapPreferredVendorApiModel } from './mapPreferredVendorApiModel'
import { type PunchOutStatusEnum } from '../../entities/enums/PunchOutStatusEnum'
import { type OrderItemConstructorData } from '../../entities/OrderItem'
import { type OrderItemApiModel } from '../api-models/OrderItemApiModel'

const mapAccount = (accountData: OrderItemApiModel['account']) => {
  return {
    accountCode: {
      id: accountData.account_code.id,
      active: accountData.account_code.active,
      code: accountData.account_code.code,
      description: accountData.account_code.description,
      type: accountData.account_code.account_type,
    },
    id: accountData.id,
    active: accountData.active,
    secondaryCategory: mapDepartment(accountData.department),
  }
}

const mapBlanketItemDetails = (
  blanketItemDetailsData: OrderItemApiModel['blanket']
) => {
  if (!blanketItemDetailsData) return null

  return {
    amount: blanketItemDetailsData.amount,
    price: blanketItemDetailsData.price,
    quantity: blanketItemDetailsData.quantity,
  }
}

const mapRecurringItemDetails = (
  recurringItemData: OrderItemApiModel['recurrence']
) => {
  if (!recurringItemData) return null

  return {
    startDate: recurringItemData.start_date,
    frequency: recurringItemData.frequency,
    duration: recurringItemData.duration,
    durationQuantity: recurringItemData.duration_quantity,
    recurringPrice: recurringItemData.recurring_price,
    periodsCount: recurringItemData.periods_count,
  }
}

const mapPunchOutSupplier = (punchoutSupplierData: {
  punchout_supplier_icon: Nullable<string>
  punchout_supplier_name: Nullable<string>
  punchout_vendor_reference: Nullable<number>
}) => {
  if (
    !punchoutSupplierData.punchout_supplier_icon ||
    !punchoutSupplierData.punchout_supplier_name ||
    punchoutSupplierData.punchout_vendor_reference === null
  )
    return null

  return {
    id: punchoutSupplierData.punchout_vendor_reference,
    icon: punchoutSupplierData.punchout_supplier_icon,
    name: punchoutSupplierData.punchout_supplier_name,
  }
}

const mapPunchOutConfirmationStatus = (punchoutConfirmationStatusData: {
  confirmation_status: Nullable<PunchOutStatusEnum>
  confirmation_status_description: Nullable<string>
}) => {
  if (
    punchoutConfirmationStatusData.confirmation_status === null ||
    punchoutConfirmationStatusData.confirmation_status_description === null
  )
    return null

  return {
    status: punchoutConfirmationStatusData.confirmation_status,
    description: punchoutConfirmationStatusData.confirmation_status_description,
  }
}

const mapPurchaseOrder = (purchaseOrderData: {
  purchase_order: Nullable<number>
  purchase_order_uuid: Nullable<string>
  po_note: string
}) => {
  if (
    purchaseOrderData.purchase_order === null ||
    purchaseOrderData.purchase_order_uuid === null
  )
    return null

  return {
    id: purchaseOrderData.purchase_order,
    uuid: purchaseOrderData.purchase_order_uuid,
    comment: purchaseOrderData.po_note,
  }
}

export const mapOrderItemApiModel = (
  orderItem: OrderItemApiModel
): OrderItemConstructorData => ({
  id: orderItem.id,
  name: orderItem.name,
  type: orderItem.type,
  status: orderItem.status,
  dateSubmitted: orderItem.submitted_date,
  dateRequired: orderItem.date_required,
  dateModified: orderItem.last_modified,
  account: mapAccount(orderItem.account),
  customFields: orderItem.custom_fields.map(mapCustomFieldValue),
  attachments: orderItem.item_attachments.map(mapAttachmentApiModel),
  sku: orderItem.sku,
  comment: orderItem.lineComment,
  currency: mapCurrency(orderItem.currency),
  preferredVendor: mapPreferredVendorApiModel(orderItem.pref_vendor),
  price: orderItem.price,
  quantity: orderItem.quantity,
  unit: orderItem.unit,
  shippingAmount: orderItem.shipping_amount,
  taxAmount: orderItem.tax_amount,
  totalCost: orderItem.total_cost,
  vendorName: orderItem.vendor,
  receivingMethod: orderItem.receive_method,
  blanketItemDetails: mapBlanketItemDetails(orderItem.blanket),
  recurringItemDetails: mapRecurringItemDetails(orderItem.recurrence),
  catalogItemRef: orderItem.catalog_item?.id || null,
  punchoutSupplier: mapPunchOutSupplier(orderItem),
  punchoutConfirmationStatus: mapPunchOutConfirmationStatus(orderItem),
  order: {
    uuid: orderItem.order_uuid,
    id: orderItem.orderNum,
  },
  purchaseOrder: mapPurchaseOrder(orderItem),
})
