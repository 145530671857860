import * as Factory from 'factory.ts'
import faker from 'faker'

import { PrimaryCategoryConstructorFactory } from '@webapp/core/entities/internal/PrimaryCategory'
import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import { type UserConstructor, User } from '@webapp/platform/iam/entities/User'
import { UserRoleConstructorFactory } from '@webapp/platform/iam/entities/UserRole'

export const UserConstructorFactory = Factory.Sync.makeFactory<UserConstructor>(
  {
    id: Factory.each(() => faker.random.number()),
    authId: Factory.each(() => faker.random.number()),
    firstName: Factory.each(() => faker.name.firstName()),
    lastName: Factory.each(() => faker.name.lastName()),
    email: Factory.each(() => faker.internet.email()),
    primaryCategory: Factory.each(() =>
      PrimaryCategoryConstructorFactory.build()
    ),
    profileImage: Factory.each(() => faker.internet.url()),
    active: Factory.each(() => faker.random.boolean()),
    position: Factory.each(() => faker.name.jobTitle()),
    role: Factory.each(() => UserRoleConstructorFactory.build()),
    approvalDelegatee: Factory.each(() => null),
  }
)

export const UserFactory = EntityFactory(User, UserConstructorFactory)
