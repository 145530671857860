import * as React from 'react'

import { useSheet } from '@procurify/ui'

import { AppContentStyled } from './styles/AppContentStyled'

interface IAppContent {}

export const AppContent = ({
  children,
}: React.PropsWithChildren<IAppContent>) => {
  const { isSheetOpen } = useSheet()

  return (
    <AppContentStyled id='app-content' isSheetOpen={isSheetOpen}>
      {children}
    </AppContentStyled>
  )
}
