import { CardStyled } from '@procurify/ui'
import styled from 'styled-components'

interface IPunchOutWelcomeStyledProps {}

export const PunchOutWelcomeStyled = styled.div<IPunchOutWelcomeStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  ${CardStyled} {
    width: ${(props) => props.theme.typography.pxToRem(800)};
    margin: auto;
  }
`
