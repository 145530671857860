import * as React from 'react'

import { useSelector } from 'react-redux'

import { IntercomAPI } from '@webapp/api/IntercomAPI'
import {
  makeSelectDomain,
  makeSelectRole,
  makeSelectUser,
} from '@webapp/core/stores/global'
import { useFeatureFlag } from '@webapp/hooks'
import { INTERCOM_APP_ID } from '@webapp/infrastructure/envs'
import { PermissionGroupNames } from '@webapp/models/PermissionGroups'
import { IntercomContainerStyled } from './styles/IntercomContainer'

export const Intercom = () => {
  const FT_4880_ENABLE_INTERCOM = useFeatureFlag('FT_4880_ENABLE_INTERCOM')
  const user = useSelector(makeSelectUser())
  const domain = useSelector(makeSelectDomain())
  const role = useSelector(makeSelectRole())

  const [intercomUserHash, setIntercomUserHash] = React.useState<string>(null)

  React.useEffect(() => {
    IntercomAPI.getIntercomUserHash()
      .then((res) => {
        if (res?.data?.user_hash) {
          setIntercomUserHash(res.data.user_hash)
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  React.useEffect(() => {
    if (
      FT_4880_ENABLE_INTERCOM &&
      window?.Intercom &&
      intercomUserHash &&
      role &&
      PermissionGroupNames.SITE_ADMINISTRATOR !== role.name
    ) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: INTERCOM_APP_ID,
        email: user.email,
        user_hash: intercomUserHash,
        name: `${user.firstName} ${user.lastName}`,
        domain,
        role: role.name,
        company: {
          name: domain,
          id: domain,
        },
        is_superuser:
          PermissionGroupNames.SITE_ADMINISTRATOR === role.name ||
          PermissionGroupNames.SUPERUSER === role.name,
      })
    }
  }, [FT_4880_ENABLE_INTERCOM, user.email, role, domain, intercomUserHash])

  if (
    FT_4880_ENABLE_INTERCOM &&
    role &&
    PermissionGroupNames.SITE_ADMINISTRATOR !== role.name
  ) {
    return <IntercomContainerStyled />
  }
  return null
}
