import { type RoleTypeEnum } from '../enums/RoleTypeEnum'
import { Permission, type PermissionConstructor } from '../Permission'

export interface RoleConstructor {
  id: number
  name: string
  type: RoleTypeEnum
  permissions: PermissionConstructor[]
  userCount: number
}

export class Role {
  id: number
  name: string
  type: RoleTypeEnum
  permissions: Permission[]
  userCount: number

  constructor(data: RoleConstructor) {
    this.id = data.id
    this.name = data.name
    this.type = data.type
    this.permissions = data.permissions.map((p) => new Permission(p))
    this.userCount = data.userCount
  }
}
