export interface PrimaryCategoryConstructor {
  id: number
  name: string
}

export class PrimaryCategory {
  id: number
  name: string

  constructor(data: PrimaryCategoryConstructor) {
    this.id = data.id
    this.name = data.name
  }
}
