import { get } from 'lodash'
import { useSelector } from 'react-redux'

import { makeSelectEndpoints } from '@webapp/core/stores/global'

export const ATTACHMENTS_URL = '/api/v3/attachments/'

export const formatMedia = (
  url: string,
  placeholder: string,
  prefix: string
): string => {
  if (!url) {
    return placeholder || ''
  }

  function pathJoin(mediaUrl: string, extraUrl: string) {
    if (extraUrl?.startsWith('/')) {
      extraUrl = extraUrl.slice(1, extraUrl.length)
    }
    return mediaUrl + extraUrl
  }

  let finalMediaUrl = pathJoin(prefix, url)

  if (!url) {
    finalMediaUrl = placeholder
  } else if (
    (url.indexOf && url.indexOf('http:') >= 0) ||
    url.indexOf('https:') >= 0
  ) {
    finalMediaUrl = url
  } else if (
    /attachments\/[0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}/.test(
      url
    )
  ) {
    if (/api\/v3\/[a-zA-Z0-9]/.test(url)) {
      finalMediaUrl = url
    } else {
      finalMediaUrl = '/api/v3/' + url
    }
  } else if (
    /^[0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}$/.test(
      url
    )
  ) {
    finalMediaUrl = `${ATTACHMENTS_URL}${url}`
  }

  return finalMediaUrl
}

export function useFormatMedia() {
  const endpoints = useSelector(makeSelectEndpoints())

  return (url: string, placeholder = '') => {
    return formatMedia(url, placeholder, get(endpoints, 'media'))
  }
}
