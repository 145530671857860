import { type AppModuleConfig } from '@webapp/app/config/useModuleConfig'
import { type FeatureFlags } from '@webapp/core/services/FeatureFlag'
import {
  type FeatureSwitches,
  type NavigationPermissions,
  type SetupProgress,
} from '@webapp/models'
import { type Enum } from '@webapp/utils'

export const TagTypes = {
  BETA: 'beta',
  NEW: 'new',
} as const

export type TagType = 'beta' | 'new'

export const NavigationSections = {
  HOME: 'dashboard',
  APPROVE: 'approve',
  EXPENSE: 'expense',
  PURCHASE: 'purchase',
  SPEND: 'spend',
  ANALYTICS: 'analytics',
  SETTINGS: 'settings',
} as const
export type NavigationSection = Enum<typeof NavigationSections>

/**
 *
 * @deprecated - remove with FT_7540_REORDER_NAVIGATION
 */
export const NavigationSectionsLegacy = {
  DASHBOARD: 'dashboard',
  PROCURE: 'procure',
  SPEND: 'spend',
  MANAGE: 'manage',
  SETTINGS: 'settings',
  WHATS_NEW: 'whats_new__procurify_payments',
} as const
/**
 *
 * @deprecated - remove with FT_7540_REORDER_NAVIGATION
 */
export type NavigationSectionLegacy = Enum<typeof NavigationSectionsLegacy>

export const NavigationHeaders = {
  HOME: 'dashboard',
  APPROVE: 'approve',
  EXPENSE_REQUESTS: 'request__expense',
  TRAVEL_REQUESTS: 'request__travel',
  SPENDING_CARD: 'spending_card',
  ORDER_REQUESTS: 'request__order',
  PURCHASE_AGREEMENT_REQUESTS: 'request__purchase_agreement',
  PROCURE: 'procure',
  RECEIVE: 'receive',
  FINANCIAL_ACCOUNT: 'ap__payment__financial_account',
  PAYMENT_LEGACY: 'payment',
  ACCOUNTS_PAYABLE: 'ap',
  REPORTS: 'reports',
  SPEND_DASHBOARD: 'data_and_insights__spend_insights',
} as const
export type NavigationHeader = Enum<typeof NavigationHeaders>

/**
 *
 * @deprecated - remove with FT_7540_REORDER_NAVIGATION
 */
export const NavigationHeadersLegacy = {
  REQUEST: 'request',
  APPROVE: 'approval',
  PROCURE: 'procure',
  RECEIVE: 'receive',
  SPENDING_CARD: 'spending_card',
  PAYMENT_LEGACY: 'payment',
  ACCOUNTING: 'ap',
  REPORTS: 'report',
  DATA_AND_INSIGHTS: 'data_and_insights',
} as const
/**
 *
 * @deprecated - remove with FT_7540_REORDER_NAVIGATION
 */
export type NavigationHeaderLegacy = Enum<typeof NavigationHeadersLegacy>

export const NavigationSubheaders = {
  AP_BILLS: 'bills',
  AP_CREDIT_CARDS: 'credit_cards',
  AP_PAYMENT_LEGACY: 'payment',
  AP_PAYMENT: 'payments',
  AP_SPEND_REPORTS: 'spend',
  PURCHASE_REPORTS: 'purchase_reports',
  EXPENSE_REPORTS: 'expense_reports',
} as const
export type NavigationSubheader = Enum<typeof NavigationSubheaders>

export interface INavigationSub {
  title?: string
  url?: string
  notificationKey?: string
  legacy?: boolean
  active?: boolean
  id?: NavigationSubheader | NavigationPermissions
  isSubheader?: boolean
  links?: NavigationPermissions[]
  condition?: (context: {
    featureFlags: Partial<FeatureFlags> & Partial<FeatureSwitches>
    setupProgress: SetupProgress
    entitlement: Partial<AppModuleConfig['navigation']> // maybe some kind of key?
  }) => boolean
  matchUrls?: string[]
  exactMatch?: boolean
  tag?: TagType
}

export interface INavigationItem {
  title?: string
  icon?: React.ElementType
  tag?: TagType
  url?: string
  legacy?: boolean
  notificationKey?: string
  children?: INavigationSub[]
  id?: NavigationHeaderLegacy | NavigationHeader | NavigationPermissions
  condition?: (context: {
    featureFlags: Partial<FeatureFlags> & Partial<FeatureSwitches>
    setupProgress: SetupProgress
    entitlement: Partial<AppModuleConfig['navigation']>
  }) => boolean
  matchUrls?: string[]
  exactMatch?: boolean
}
