import { useMemo } from 'react'

import { useQuery } from 'react-query'

import { userRepository } from '@webapp/platform/iam/data-access/repositories/UserRepository'
import { userKeys } from '@webapp/platform/iam/data-access/repositories/UserRepository/queryKey.factory'
import { DetailedUser } from '@webapp/platform/iam/entities/DetailedUser'

type UseQueryUserDetailsReturn = {
  data: {
    user: DetailedUser
  }
  commands: {
    refetch: () => void
  }
  states: {
    isFetching: boolean
  }
}

export const useQueryUserDetails = ({
  userId,
}: {
  userId: number
}): UseQueryUserDetailsReturn => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [...userKeys.details, userId],
    queryFn: () => userRepository.getDetails(userId),
    staleTime: 5000,
  })

  const { user } = useMemo(() => {
    if (!data) return { user: undefined }
    return {
      user: new DetailedUser(data.data),
    }
  }, [data])

  return {
    data: {
      user,
    },
    commands: {
      refetch,
    },
    states: {
      isFetching,
    },
  }
}
